import React, { useEffect, useState } from 'react';
import { userToken, executeLaravelAPI } from '../../Utils';
import { Tabs, Tab, Card } from 'react-bootstrap';
import ProjectEditBasicDetails from './ProjectEditBasicDetails';
import ProjectEditPriceConfiguration from './ProjectEditPriceConfiguration';
import ProjectEditAmenities from './ProjectEditAmenities';
import ProjectEditSpecifications from './ProjectEditSpecifications';
import ProjectEditLocation from './ProjectEditLocation';
import ProjectEditAssets from './ProjectEditAssets';
import ProjectEditDeveloper from './ProjectEditDeveloper';
import ProjectEditFloorPlans from './ProjectEditFloorPlans';
import ProjectEditUnitPlans from './ProjectEditUnitPlans';
import ProjectEditBrochures from './ProjectEditBrochures';
import ProjectEditOffers from './ProjectEditOffers';
import ProjectEditCostsheet from './ProjectEditCostsheet';
import ProjectUploadeThumbnails from './ProjectUploadThumbnails';

const ProjectEditForm = ({ hideEditForm, selectedProjectId,selectedProjectAddressId, selectedProjectDeveloperId }) => {
    const pageDetails={'pagetitle':'Edit Project','pageslug':'edit-project'};
    const [activeKey, setActiveKey] = useState('basicDetails');
    const [editedItem, setEditedItem] = useState({});
    // console.log(selectedProjectDeveloperId);
    useEffect(() => {
        document.title = pageDetails.pagetitle;
        const getProjects = async () => {
            try {
                const result = await executeLaravelAPI(`project/view/${selectedProjectId}`, '', 'GET', userToken);
                const dataProjectDetails = result.data;
                setEditedItem(dataProjectDetails);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (selectedProjectId) {
            getProjects();
        }
    }, [selectedProjectId]);

    const handleTabSelect = (key) => {
        setActiveKey(key);
    };

    // const handleCloseForm = () => {
    //     hideEditForm(true);
    // };

    return (
        // <form onSubmit={updateProject} className='projectForm'>
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Edit {editedItem.name} Project</Card.Header>
                        <Card.Body>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <Tabs id="controlled-tab-example" activeKey={activeKey} onSelect={handleTabSelect} className="mb-3 addProjectTab">
                                        <Tab eventKey="basicDetails" title="Basic Details">
                                            <ProjectEditBasicDetails selectedProjectId={selectedProjectId} />
                                        </Tab>
                                        <Tab eventKey="priceConfiguration" title="Price Configuration">
                                        {activeKey === 'priceConfiguration' && <ProjectEditPriceConfiguration selectedProjectId={selectedProjectId} /> }
                                        </Tab>
                                        <Tab eventKey="ammenities" title="Amenities">
                                            {activeKey === 'ammenities' && <ProjectEditAmenities selectedProjectId={selectedProjectId} />}
                                        </Tab>
                                        <Tab eventKey="specifications" title="Specifications">
                                            {activeKey === 'specifications' && <ProjectEditSpecifications selectedProjectId={selectedProjectId} />}
                                        </Tab>
                                        <Tab eventKey="location" title="Location">
                                           {activeKey === 'location' && <ProjectEditLocation selectedProjectAddressId={selectedProjectAddressId} selectedProjectDeveloperId={selectedProjectDeveloperId} />}
                                        </Tab>
                                        <Tab eventKey="imagesDocs" title="Images & Docs">
                                           {activeKey === 'imagesDocs' && <ProjectEditAssets selectedProjectId={selectedProjectId} />}
                                        </Tab>
                                        <Tab eventKey="gallerythumbnails" title="Gallery Thumbnails">
                                             {activeKey === 'gallerythumbnails' && <ProjectUploadeThumbnails selectedProjectId={selectedProjectId} />}
                                        </Tab>
                                        <Tab eventKey="floorPlans" title="Floor Plans">
                                           {activeKey === 'floorPlans' && <ProjectEditFloorPlans selectedProjectId={selectedProjectId} />}
                                        </Tab>
                                        <Tab eventKey="unitPlans" title="Unit Plans">
                                            <ProjectEditUnitPlans selectedProjectId={selectedProjectId} />
                                        </Tab>
                                        <Tab eventKey="brochures" title="Brochures">
                                           {activeKey === 'brochures' && <ProjectEditBrochures selectedProjectId={selectedProjectId} />}
                                        </Tab>
                                        <Tab eventKey="devloper" title="Developer">
                                           {activeKey === 'devloper' && <ProjectEditDeveloper selectedProjectDeveloperId={selectedProjectDeveloperId} />}
                                        </Tab>
                                        <Tab eventKey="offers" title="Offers">
                                           {activeKey === 'offers' && <ProjectEditOffers selectedProjectId={selectedProjectId} />}
                                        </Tab>
                                        <Tab eventKey="costsheet" title="Costsheet">
                                           {activeKey === 'costsheet' && <ProjectEditCostsheet selectedProjectId={selectedProjectId} />}
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </Card.Body>
                        {/* <Card.Footer className="text-end text-muted">
                            <Button variant="secondary" onClick={handleCloseForm} className="theme-btn secondary me-2">Cancel</Button>
                            <Button variant="success" type="submit" className="theme-btn">Save</Button>
                        </Card.Footer> */}
                    </Card>
                </div>
            </div>
        // </form>
    )   
}
export default ProjectEditForm;
