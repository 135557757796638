

import React, { useEffect, useState, useRef, useContext, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { appAbsPath, executeLaravelFrontAPI, s3url, getSortBHKConf, createGa4Event, formatNumber, getMinMaxPrice, parsedUserData, lazyLoadImages } from '../../../Admin/Utils';
import BannerUnit from '../../../../Assets/img/bannerlogo.png';
import irfsbannerbg from '../../../../Assets/img/irfsbannerbg.png';
import bannerupper from '../../../../Assets/img/bannerupperbg.png';
import irfsbnrlogo from '../../../../Assets/img/irfsbnrlogo.png';
import bnrbuilds from '../../../../Assets/img/bnrbuilds.png';
import support from '../../../../Assets/img/support.svg';
import projects from '../../../../Assets/img/projects.svg';
import deals from '../../../../Assets/img/deals.svg';
import logogrid from '../../../../Assets/img/logogrid.png';
import patterntl from '../../../../Assets/img/patterntl.png';
import patternbl from '../../../../Assets/img/patternbl.png';
import patterntr from '../../../../Assets/img/patterntr.png';
import patternbr from '../../../../Assets/img/patternbr.png';
import discount from '../../../../Assets/img/discount.svg';
import btm1 from '../../../../Assets/img/btm1.svg';
import btm2 from '../../../../Assets/img/btm2.svg';
import btm3 from '../../../../Assets/img/btm3.svg';
import bnrright from '../../../../Assets/img/bnrright.png';
import bgg from '../../../../Assets/img/bgg.png';
import bnrsharman from '../../../../Assets/img/bnr-sharman.png';
import irfsbuild from '../../../../Assets/img/irfsbuild.png';
import irfsfinallogo from '../../../../Assets/img/irfsfinallogo.png';
import bnrrightmob from '../../../../Assets/img/bnrright-mob-up.png';
import SearchPopup from "../../Layouts/SearchPopup";
import SearchInput from "../../Layouts/SearchInput";

const Banner = () => {
    const [showResult, showSearchResult] = useState(false);
    const searchInputRef = useRef(null);
    const [projects, setResponseData] = useState([]);
    const suggestionContainerRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    const [isIrfsPath, setIsIrfsPath] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        // Add event listener to track window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        const path = window.location.pathname;
        if (path.includes('irfs')) {
            setIsIrfsPath(true);
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        
    }, []);

    const handleOutsideClick = (e) => {
        if (
            suggestionContainerRef.current &&
            !suggestionContainerRef.current.contains(e.target) &&
            searchInputRef.current &&
            !searchInputRef.current.contains(e.target)
        ) {
            showSearchResult(false);
            // console.log("Outside click");
        }
    };
    useEffect(() => {
        // getProjects();
        document.addEventListener('mousedown', handleOutsideClick);
        // const storedCity = sessionStorage.getItem('city');
        // if (storedCity) {
        // setCurrCity(storedCity);
        // }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []); // Run the effect only once during component mount

    // const getProjects = async () => {
    //     const paramsData = {};
    //     paramsData.is_mandate = true;
    //     paramsData.per_page = 15;

    //     let result = {};
    //     try {
    //         result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
    //         setResponseData(result.data.data);
    //         // setTotalItems(result.data.total);
    //         // console.log(result.data.data);

    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {
    //     }
    // }

    return(<>
        <Helmet>
                <title>Indian Realty Flash Sale (IRFS) | Pune Edition - 2024</title>
                <meta name="description" content="Discover the best deals on top properties in Pune at the Indian Realty Flash Sale (IRFS), the biggest real estate sale in Pune,  from 1st Sept-31st Oct 2024." />
                <link rel="canonical" href="https://www.beyondwalls.com/irfs" />
                <meta property="og:url" content="https://www.beyondwalls.com/irfs" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Indian Realty Flash Sale (IRFS) | Pune Edition - 2024" />
                <meta property="og:description" content="Discover the best deals on top properties in Pune at the Indian Realty Flash Sale (IRFS), the biggest real estate sale in Pune,  from 1st Sept-31st Oct 2024." />
                <meta property="og:image" content="https://www.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg"></meta>

                <meta name="twitter:card" content="summary_large_image"></meta>
                <meta property="twitter:domain" content="beyondwalls.com"></meta>
                <meta property="twitter:url" content="https://www.beyondwalls.com/irfs"></meta>
                <meta name="twitter:title" content="Indian Realty Flash Sale (IRFS) | Pune Edition - 2024"></meta>
                <meta name="twitter:description" content="Discover the best deals on top properties in Pune at the Indian Realty Flash Sale (IRFS), the biggest real estate sale in Pune,  from 1st Sept-31st Oct 2024."></meta>
                <meta name="twitter:image" content="https://www.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg"></meta>

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org", 
                        "@type": "FAQPage",
                        "mainEntity": [{ 
                        "@type": "Question", 
                        "name": "What is IRFS?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "The Indian Realty Flash Sale (IRFS) is the biggest real estate sale with the best deals on top properties in Pune. Top properties, AI-powered search, property compare and exclusive deals on your dream home – is what IRFS brings you." 
                        } 
                        },{ 
                        "@type": "Question", 
                        "name": "Why should I participate in IRFS?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "IRFS presents homebuyers with an exclusive opportunity to buy their dream home. A transparent and hassle-free home buying experience with comprehensive project details. Choose from handpicked projects, compare your top choices, and get assured savings when you book during the event period." 
                        } 
                        },{ 
                        "@type": "Question", 
                        "name": "How do I participate in IRFS?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "To participate in IRFS all you need to sign up. Once signed up, discover properties, download comprehensive project brochures, and compare your top choices. Shortlist the ones you love, and get a Privilege Card that assures your ₹2.5 Lakhs* savings on your dream home." 
                        } 
                        },{ 
                        "@type": "Question", 
                        "name": "When will IRFS take place?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "IRFS will run from 1st September to 31st October 2024, with special offers and extended coupon redemption periods." 
                        } 
                        },{ 
                        "@type": "Question", 
                        "name": "How can developers participate in IRFS?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "Developers can register through the BeyondWalls portal, where they can list their projects and gain access to a wide audience of potential buyers." 
                        } 
                        },{ 
                        "@type": "Question", 
                        "name": "What benefits do developers get from participating in IRFS?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "Participating developers gain maximum visibility, access to exclusive marketing campaigns, and the opportunity to connect with a large pool of motivated buyers." 
                        } 
                        },{ 
                        "@type": "Question", 
                        "name": "What is the IRFS portal, and how does it work?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "The IRFS portal, powered by BeyondWalls, allows users to browse listed properties, compare projects, and access exclusive deals, all within an AI-powered platform." 
                        }
                        },{ 
                        "@type": "Question", 
                        "name": "How is IRFS different from regular real estate events?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "IRFS offers a concentrated marketing effort, exclusive deals, and an AI-powered platform, making it a unique and impactful event for both developers and buyers." 
                        }
                        },{ 
                        "@type": "Question", 
                        "name": "What kind of properties will be featured in IRFS?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "IRFS will feature a wide range of residential properties across various segments, catering to different buyer preferences and budgets." 
                        }
                        },{ 
                        "@type": "Question", 
                        "name": "Can homebuyers redeem their coupons after 31st October?", 
                        "acceptedAnswer": { 
                            "@type": "Answer", 
                            "text": "No, coupons must be redeemed by 31st October 2024. After this date, they will no longer be valid." 
                        }
                        }] 
                    })}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org", 
                        "@type": "Event", 
                        "name": "Indian Realty Flash Sale Pune Edition 2024",
                        "description": "Discover the best deals on top properties in Pune at the Indian Realty Flash Sale (IRFS), the biggest real estate sale in Pune,  from 1st Sept-31st Oct 2024.",
                        "image": "https://www.beyondwalls.com/static/media/bannerlogo.78e141919dd690010a98.png",
                        "startDate": "2024-09-01",
                        "endDate": "2024-10-31",
                        "eventStatus": "https://schema.org/EventScheduled",
                        "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
                        "location": { 
                            "@type": "VirtualLocation",
                            "url": "https://www.beyondwalls.com/irfs"
                        },
                        "performer": { 
                            "@type": "PerformingGroup",
                            "name": "BeyondWalls"
                        }
                    })}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebPage",
                        "name": "Indian Realty Flash Sale (IRFS) | Pune Edition - 2024",
                        "speakable":
                        {
                        "@type": "SpeakableSpecification",
                        "xPath": [
                            "/html/head/title",
                            "/html/head/meta[@name='description']/@content"
                            ]
                        },
                        "url": "https://www.beyondwalls.com/irfs"
                    })}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "SearchResultsPage",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.beyondwalls.com/irfs"
                        },
                        "headline": "Indian Realty Flash Sale (IRFS) | Pune Edition - 2024",
                        "description": "Discover the best deals on top properties in Pune at the Indian Realty Flash Sale (IRFS), the biggest real estate sale in Pune,  from 1st Sept-31st Oct 2024.",
                        "image": "https://www.beyondwalls.com/static/media/bannerlogo.78e141919dd690010a98.png",
                        "author": {
                            "@type": "Organization",
                            "name": "BeyondWalls",  
                            "url": "https://www.beyondwalls.com/"
                        }, 
                        "publisher": {
                            "@type": "Organization",
                            "name": "BeyondWalls",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://www.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg"
                            }
                        }
                    })}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",   
                        "@type": "WebSite",
                        "url": "https://www.beyondwalls.com/irfs",
                        "name":"Indian Realty Flash Sale (IRFS) | Pune Edition - 2024",
                        "description":"Discover the best deals on top properties in Pune at the Indian Realty Flash Sale (IRFS), the biggest real estate sale in Pune,  from 1st Sept-31st Oct 2024."
                    })}
                </script>
            </Helmet>
        {/* <section className='pt-lg-5 pb-4 irfsbanner position-relative'>
            <div className='container'>
                <div className='bannerupperdiv position-relative'>
                    <img src={bannerupper} className="img-fluid bannerupper"/>
                    <div className='d-flex justify-content-center uppercontent'>
                        <div className='d-flex contentdiv'>
                            <p className='mb-0 limited'>PUNE's BIGGEST HOMEBUYING OPPORTUNITY!</p>
                        </div>
                    </div>
                </div>
                <div className='bnrbgdiv position-relative'>
                    <img src={irfsbannerbg} className="img-fluid irfsbannerbg"/>
                    <img src={irfsbnrlogo} className="img-fluid bannerlogo"/>
                    <img src={bnrbuilds} className="img-fluid bnrbuilds"/>
                    <p className='poweredby text-white text-align-center'>Powered By Aquarius</p>
                    <div className='bluefooter'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                <img src={projects} className="img-fluid me-2"/>
                                <p className='mb-0 iftxt'>60+ Projects</p>
                            </div>
                            <div className='d-flex align-items-center'>
                                <img src={deals} className="img-fluid me-2"/>
                                <p className='mb-0 iftxt'>Best Deals</p>
                            </div>
                            <div className='d-flex align-items-center'>
                                <img src={support} className="img-fluid me-2"/>
                                <p className='mb-0 iftxt'>End-to-end Support </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        
        <section className='pt-lg-5 pb-4 irfsbanner position-relative'>
            <div className='container bannercontainer'>
                <div className='row bannerbg'>
                    <img src={patterntl} className="img-fluid patterntl d-none d-sm-block"/>
                    <img src={patternbl} className="img-fluid patternbl d-none d-sm-block"/>
                    <img src={patterntr} className="img-fluid patterntl patterntl1 d-none d-sm-block"/>
                    <img src={patternbr} className="img-fluid patternbl patternbl1 d-none d-sm-block"/>
                    <div className='col-12 col-lg-6 position-relative p-0'>
                        <div className='bnrcontent'>
                            <div className='logogrid'>
                                <img src={logogrid} className="img-fluid logogrid"/>
                            </div>
                            <h2 className='bannertitle'>Time Abhi, <br className='d-none d-sm-block'></br>Daam Sahi</h2>
                            <div className='discountdiv'>
                                <p className='mb-0 disc'><span><img src={discount} className="img-fluid"/></span>Amazing Offers On Your Dream Home</p>
                            </div>
                            <div className='d-none d-sm-block'>
                                <div className="hero-searchmain position-relative col-lg-8 col-12 align-self-center" ref={searchInputRef}>
                                    <div className="text-start mt-3 my-lg-4 position-relative">
                                        <div className="search-component">
                                            {isMobile ? (
                                                <SearchPopup buttonShow={true} iconShow={false} irfs={isIrfsPath} />
                                            ) : (
                                                <SearchInput irfs={isIrfsPath}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='btmdiv'>
                            <p><span><img src={btm1} className="img-fluid"/></span>60+ Projects</p>
                            <p><span><img src={btm3} className="img-fluid"/></span>Top Developers</p>
                            <p><span><img src={btm2} className="img-fluid"/></span>Best Deals</p>
                        </div>
                        <div className='themeBtnWrapper'>
                            <Link to={`${appAbsPath}/irfs/projects`} className="themeBtn">View All Projects</Link>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 position-relative'>
                        <img src={bnrrightmob} className="img-fluid bnrrightmob d-block d-sm-none"/>
                        <div className='d-none d-sm-block'>
                            <div className='rightflex'>
                                <img src={bnrright} className="img-fluid position-relative"/>
                                <div className='rightcontent'>
                                    <img src={irfsfinallogo} className="img-fluid irfsfinallogo"/>
                                    <p className='lim'>Limited Period Offers</p>
                                    <h2 className='bumper'>Bumper Savings</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={bnrsharman} className="img-fluid bnrsharman d-none d-sm-block"/>
                    <img src={irfsbuild} className="img-fluid irfsbuild"/>
                </div>
            </div>
        </section>
    </>);
}

export default Banner;