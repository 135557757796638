import React, { useState, useEffect, Suspense } from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { appAbsPath, userToken, executeLaravelAPI, loadGTMWithTimeout, loadClarityWithTimeout } from './Components/Admin/Utils';
import { SearchVisibilityProvider } from './Components/Front/Layouts/SearchVisibilityContext';
import { CompareProjectProvider } from './Components/Front/Layouts/CompareProjectContext';
import { setUserId, updateAllCookiesData } from './Components/Admin/UserActivatyTracker';
//React GA
import { InitializeGoogleAnalytics } from './Components/Admin/GoogleAnalytics';
//Front Layout
import FrontHeader from './Components/Front/Layouts/Header';
//import FrontFooter from './Components/Front/Layouts/Footer';

//Front Custom Components
import FrontProjectsList from './Components/Front/Pages/ProjectList';

// import FrontBlogsList from './Components/Front/Pages/Blogs/BlogsList';
// import FrontBlogDetails from './Components/Front/Pages/Blogs/BlogDetails';

// import FrontMapProjectsList from './Components/Front/Pages/MapViewProjectList';
// import NewFrontMapProjectsList from './Components/Front/Pages/NewMapViewProjectList';
import NewMapView from './Components/Front/Pages/NewMapView';
// import NewMaps from './Components/Front/Pages/NewMaps';
import ProjectDetails from './Components/Front/Pages/ProjectDetails';
import Home from './Components/Front/Home';
import UserActivity from './Components/Front/Pages/UserActivity';
import WishListDetails from './Components/Front/Pages/WishListDetails';
import ComparisonLayout from './Components/Front/Pages/ComparisonLayout';
import LocationPage from './Components/Front/Pages/Location/Localitydetails';
import Page404 from './Components/Front/Pages/Page404';
import PunePage from './Components/Front/Pages/Pune/PuneLocalities';
import ComparePage from './Components/Front/Pages/Compare/Compare';
import MyActivity from './Components/Front/Pages/MyActivity';
import BlogDetail from './Components/Front/Pages/BlogDetail';
import BlogsListing from './Components/Front/Pages/BlogsListing';
import BlogCatogeryListing from './Components/Front/Pages/Blogs/BlogCatogeryListing';
import IRFS from './Components/Front/Pages/IRFS/IRFS';
import PDP from './Components/Front/Pages/IRFS/PDP';


// import Footer from './Components/Front/Layouts/Footer';

//Admin Layout
import Header from './Components/Admin/Layouts/Header';
import Sidebar from './Components/Admin/Layouts/Sidebar';
import Footer from './Components/Admin/Layouts/Footer';

// Admin Custom Components
import Login from './Components/Admin/Auth/Login';
import Dashboard from './Components/Admin/Pages/Dashboard';
import ProjectsList from './Components/Admin/Pages/Project/ProjectsList';
import VerifiedProjectList from './Components/Admin/Pages/Project/VerifiedProjectList';
import UpdatedProjectList from './Components/Admin/Pages/Project/UpdatedProjectList';
import ProjectForm from './Components/Admin/Pages/ProjectForm';
import Leads from './Components/Admin/Pages/Project/Leads';
import DeveloperAddNewForm from './Components/Admin/Pages/Developer/DeveloperAddNew';
import OfferAddNewForm from './Components/Admin/Pages/Offer/OfferAddNew';
import LocationAddNewForm from './Components/Admin/Pages/Location/LocationAddNew';
import LocationEditForm from './Components/Admin/Pages/Location/LocationEdit';
import DisabledProjectList from './Components/Admin/Pages/Project/DisabledProjectList';
import UserActivityData from './Components/Front/Pages/UserActivityData';

import LocationsList from './Components/Admin/Pages/Location/LocationsList';
import ShortUpdatesList from './Components/Admin/Pages/ShortUpdates/ShortUpdatesList';
import ShortUpdateAddNew from './Components/Admin/Pages/ShortUpdates/ShortUpdateAddNew';
import ShortUpdateEdit from './Components/Admin/Pages/ShortUpdates/ShortUdateEdit';
import CreateJUser from './Components/Front/Pages/CreateJUser';

import './App.scss';
import UserCouponListing from './Components/Front/Pages/Profile/UserCouponListing';
// import 'lazysizes';

const FrontFooter = React.lazy(() => import('./Components/Front/Layouts/Footer'));
// const TRACKING_ID = "G-N707E6NXV1"; // your Measurement ID
const App = () => {
    const [isAdminLoggedIn, setAdminLoggedIn] = useState(false);
    const [isUserLoggedIn, setUserLoggedIn] = useState(false);
    const [loggedUserData, setUserData] = useState({});
    const [loading] = useState(false);
    const [progress] = useState(0);
    // const containsPortaladmin = window.location.pathname.includes("portaladmin");
    //alert('Login Page');
    useEffect(() => {
        setUserId()
        // ReactGA.initialize(TRACKING_ID);
        // Send pageview with a custom path
        // ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
    }, [])
    useEffect(() => {
        // Load GTM after a 5-second delay and without Partytown integration
        loadClarityWithTimeout(5000);
        loadGTMWithTimeout('GTM-N8WD9SV', false, 6000);
    }, []);

    useEffect(() => {
        InitializeGoogleAnalytics();
        //progressBar(setLoading, setProgress);
        if (userToken !== null) {
            const checkAuthentication = async () => {
                try {
                    // Make a request to your Laravel backend to check if the user is authenticated
                    const userdata = await executeLaravelAPI('check-auth', '', 'GET', userToken);
                    if (userdata.status === 200) {
                        setUserData(userdata.user)
                        Cookies.set('userData', JSON.stringify(userdata.user), { expires: 2 });
                        // console.log("User Data updateAllCookiesData", userdata.user);
                        updateAllCookiesData();
                        if (userdata.user.userrole === 'superadmin' || userdata.user.userrole === 'admin') {
                            setAdminLoggedIn(true);
                            //window.location.href=`${appAbsPath}/portaladmin/dashboard`;
                            // console.log('User Logged In Admin '+ userdata.user);
                        } else {
                            setUserData(userdata.user);

                            // console.log('User Logged In ' + userDetails.user.name);
                            setUserLoggedIn(true);

                        }
                    } else {
                        localStorage.removeItem('bwToken'); localStorage.clear();
                        setAdminLoggedIn(false);
                        setUserLoggedIn(false);
                    }
                } catch (error) {
                    console.error('Error checking authentication:', error);
                }
            };
            checkAuthentication();
        } else {
            setAdminLoggedIn(false);
            setUserLoggedIn(false);
            //window.location.href=`${appAbsPath}`;
        }
    }, []);

    const handleLogin = (userdetails) => {
        localStorage.setItem('bwToken', userdetails.access_token);
        //setCookie('bwToken', userdetails.access_token);
        Cookies.set('userData', JSON.stringify(userdetails.user), { expires: 2 });
        setUserData(userdetails.user);
        if (userdetails.user.userrole === 'superadmin' || userdetails.user.userrole === 'admin') {
            setAdminLoggedIn(true);
            // window.location.href = `${appAbsPath}/portaladmin/dashboard`;
        } else {
            setUserLoggedIn(true);


            if (window.location.pathname.includes('useractivitydata')) {
                setTimeout(() => {
                    // window.location.href = `${appAbsPath}`;
                }, 5000);
            } else {
                setTimeout(() => {
                    // window.location.href = `${appAbsPath+window.location.href}`;
                }, 3000);
            }
        }
    };

    // const NotFoundPage = () => (
    //     <div>
    //         <h2>404 Not Found</h2>
    //         <p>Sorry, the page you are looking for does not exist.</p>
    //     </div>
    // );

    const showHeaderAndFooter = () => {
        const path = window.location.pathname;
        // Regex to match any numbers following the base path
        const userActivityRegex = /^\/useractivitydata\/\d+$/;
        if (userActivityRegex.test(path)) {
            return false;
        }
        return true;
    };

    // console.log(loggedUserData);
    // console.log(isUserLoggedIn);
    //console.log(Cookies.get('userData') ? JSON.parse(Cookies.get('userData')) : null)
    return (
        <div>
            <ToastContainer />

            {isAdminLoggedIn ?
                (
                    <>
                        {loading ? (
                            <div>
                                <progress value={progress} max="100" style={{ width: '100%' }} />
                            </div>
                        ) : (
                            <BrowserRouter>
                                <div className="container-scroller">
                                    {showHeaderAndFooter() && (
                                        <SearchVisibilityProvider>
                                            <Header loggeduserdetails={loggedUserData} />
                                        </SearchVisibilityProvider>
                                    )}
                                    <div className="container-fluid page-body-wrapper">
                                        <Sidebar />
                                        <div className="main-panel">
                                            <div className="content-wrapper">
                                                <Routes>
                                                    {/* <Route path={`${appAbsPath}`} element={<Home />} />    */}
                                                    <Route path="*" element={<Navigate to={`${appAbsPath}/portaladmin/dashboard`} />} />
                                                    <Route path={`${appAbsPath}/portaladmin/dashboard`} element={<Dashboard />} />
                                                    <Route path={`${appAbsPath}/portaladmin/projects`} element={<ProjectsList />} />
                                                    <Route path={`${appAbsPath}/portaladmin/add/project`} element={<ProjectForm />} />
                                                    <Route path={`${appAbsPath}/portaladmin/add/developer`} element={<DeveloperAddNewForm />} />
                                                    <Route path={`${appAbsPath}/portaladmin/add/offer`} element={<OfferAddNewForm />} />
                                                    <Route path={`${appAbsPath}/portaladmin/add/location`} element={<LocationAddNewForm />} />
                                                    <Route path={`${appAbsPath}/portaladmin/locations`} element={<LocationsList />} />
                                                    <Route path={`${appAbsPath}/portaladmin/location/edit/:id`} element={<LocationEditForm />} />
                                                    <Route path={`${appAbsPath}/portaladmin/verifiedprojects`} element={<VerifiedProjectList />} />
                                                    <Route path={`${appAbsPath}/portaladmin/updatedprojects`} element={<UpdatedProjectList />} />
                                                    <Route path={`${appAbsPath}/portaladmin/disabledprojects`} element={<DisabledProjectList />} />
                                                    <Route path={`${appAbsPath}/portaladmin/leads`} element={<Leads />} />

                                                    <Route path={`${appAbsPath}/portaladmin/shortupdates`} element={<ShortUpdatesList />} />
                                                    <Route path={`${appAbsPath}/portaladmin/add-short-update`} element={<ShortUpdateAddNew />} />
                                                    <Route path={`${appAbsPath}/portaladmin/shortupdates/edit/:id`} element={<ShortUpdateEdit />} />
                                                </Routes>
                                            </div>
                                            <Footer />
                                        </div>
                                    </div>
                                </div>
                            </BrowserRouter>
                        )}
                    </>
                ) : (
                    // isUserLoggedIn ? (
                    <>
                        <BrowserRouter>
                            <SearchVisibilityProvider>
                                {showHeaderAndFooter() && (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <FrontHeader loggeduserdetails={loggedUserData} onLogin={handleLogin} />
                                    </Suspense>
                                )}

                                <Routes>
                                    <Route path={`${appAbsPath}`} element={<Home loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />
                                </Routes>

                                <CompareProjectProvider>
                                    <Routes>
                                        {isUserLoggedIn ? (
                                            <>
                                                <Route path={`${appAbsPath}/profile`} element={<UserActivity onLogin={handleLogin} />} />

                                                {loggedUserData.userrole === 'developer' && (

                                                    <Route path={`${appAbsPath}/profile/user-coupons`} element={<UserCouponListing onLogin={handleLogin} />} />
                                                )};
                                                {loggedUserData.userrole === 'user' && (
                                                    <Route path={`${appAbsPath}/profile/my-coupons`} element={<UserCouponListing onLogin={handleLogin} />} />
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <Route path={`${appAbsPath}/useractivitydata/:selldoid`} element={<UserActivityData loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />

                                        <Route path={`${appAbsPath}/user/wishlist`} element={<WishListDetails loggeduserdetails={loggedUserData} />} />
                                        <Route path={`${appAbsPath}/projects`} element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />
                                        {/* <Route path={`${appAbsPath}/projects/map-view`} element={<FrontMapProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />} /> */}
                                        {/* <Route path={`${appAbsPath}/projects/new-map-view`} element={<NewFrontMapProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />} /> */}
                                        <Route path={`${appAbsPath}/projects/map-view`} element={<NewMapView loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />
                                        {/* <Route path={`${appAbsPath}/projects/map-view`} element={<NewMapView loggeduserdetails={loggedUserData} onLogin={handleLogin} />} /> */}
                                        {/* <Route path={`${appAbsPath}/projects/newmaps`} element={<NewMaps loggeduserdetails={loggedUserData} onLogin={handleLogin} />} /> */}
                                        <Route path={`${appAbsPath}/project/:projectslug`} element={<ProjectDetails loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/irfs/project/:projectslug`} element={<ProjectDetails loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/pdp/:projectslug`} element={<PDP loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/portaladmin/login`} element={<Login onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/portaladmin`} element={<Login onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/projects/comparison`} element={<ComparisonLayout onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/location`} element={<LocationPage onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/location/:slug`} element={<LocationPage onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/pune`} element={<PunePage onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/compare`} element={<ComparePage onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/irfs`} element={<IRFS onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/irfs/projects`} element={<FrontProjectsList onLogin={handleLogin} />} />

                                        {/* <Route path={`${appAbsPath}/pdp`} element={<PDP onLogin={handleLogin} />} /> */}
                                        <Route path={`${appAbsPath}/myactivity`} element={<MyActivity onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/blogdetail`} element={<BlogDetail onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/blogslisting`} element={<BlogsListing onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/404`} element={<Page404 onLogin={handleLogin} />} />

                                        <Route path={`${appAbsPath}/blogs`} element={<BlogsListing loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/blog`} element={<Navigate to={`${appAbsPath}/blogs`} />} />
                                        <Route path={`${appAbsPath}/blog/:slug`} element={<BlogDetail loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />
                                        <Route path={`${appAbsPath}/blogs/:catogery`} element={<BlogCatogeryListing loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />



                                        <Route
                                            path={`${appAbsPath}/:bedroom-bhk-flats-for-sale-in-locality-city`}
                                            element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />}
                                        />
                                        <Route
                                            path={`${appAbsPath}/projects/:bedroom-bhk-flats-for-sale-in-:city`}
                                            element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />}
                                        />
                                        <Route
                                            path={`${appAbsPath}/projects/property-in-:city`}
                                            element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />}
                                        />

                                        <Route
                                            path={`${appAbsPath}/projects/ready-to-move-in-:locality-:city`}
                                            element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />}
                                        />

                                        <Route
                                            path={`${appAbsPath}/projects/what-a-view-in-:locality-:city`}
                                            element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />}
                                        />

                                        <Route
                                            path={`${appAbsPath}/projects/new-in-:locality-:city`}
                                            element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />}
                                        />

                                        <Route
                                            path={`${appAbsPath}/projects/hot-selling-in-:locality-:city`}
                                            element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />}
                                        />

                                        <Route
                                            path={`${appAbsPath}/projects/affordable-homes-in-:locality-:city`}
                                            element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />}
                                        />

                                        <Route
                                            path={`${appAbsPath}/projects/live-in-sky-in-:locality-:city`}
                                            element={<FrontProjectsList loggeduserdetails={loggedUserData} onLogin={handleLogin} />}
                                        />
                                        <Route path={`${appAbsPath}/usercreate`} element={<CreateJUser loggeduserdetails={loggedUserData} onLogin={handleLogin} />} />


                                    </Routes>
                                    {showHeaderAndFooter() && (
                                        <FrontFooter />
                                    )}

                                </CompareProjectProvider>
                            </SearchVisibilityProvider>
                        </BrowserRouter>
                    </>
                )
            }
        </div>
    );
};
export default App;
