
import React, { Suspense, useRef, useState, useEffect } from 'react';
import { appAbsPath, createGa4Event, executeLaravelFrontAPI, lazyLoadImages } from '../../../Admin/Utils';
import { Icon } from '@iconify/react';
import { OverlayTrigger, Tooltip, Carousel, Accordion } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../../../../Assets/css/OnlyIrfs.scss';
import Banner from './Banner';
import ProjectCard from '../../Layouts/ProjectCard';
import SearchPopupIrfs from "../../Layouts/SearchPopupIrfs";
import SearchInputIrfs from "../../Layouts/SearchInputIrfs";
import Joshi from '../../../../Assets/img/joshi.png';
import Sharman from '../../../../Assets/img/sharman.png';
import Testimoni from '../../../../Assets/img/testimoni.png';
import House1 from '../../../../Assets/img/house1.png';
import House2 from '../../../../Assets/img/house2.png';
import House3 from '../../../../Assets/img/house3.png';
import Searchbar from '../../../../Assets/img/searchbar.svg';
const Overview = React.lazy(() => import('./Overview'));
const ExploreProjectsLocalities = React.lazy(() => import('../Home/ExploreProjectsLocalities'));



const IRFS = ({ isWishlistActive }) => {
    const [showResult, showSearchResult] = useState(false);
    const sectionRef = useRef(null);
    const [projects, setResponseData] = useState([]);
    const [isIrfsPath, setIsIrfsPath] = useState(false);

        // Out side click hide div
        const searchInputRef = useRef(null);
        const suggestionContainerRef = useRef(null);
    
        const handleOutsideClick = (e) => {
            if (
                suggestionContainerRef.current &&
                !suggestionContainerRef.current.contains(e.target) &&
                searchInputRef.current &&
                !searchInputRef.current.contains(e.target)
            ) {
                showSearchResult(false);
                // console.log("Outside click");
            }
        };

    useEffect(() => {
        getProjects();
        const path = window.location.pathname;
        if (path.includes('irfs')) {
            setIsIrfsPath(true);
        }
    }, []); // Run the effect only once during component mount
    useEffect(() => {
        const timer = setTimeout(() => {
            lazyLoadImages();
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    const getProjects = async () => {
        const paramsData = {};
        // paramsData.is_mandate = true;
        paramsData.per_page = 15;
        paramsData.is_irfs = true;

        let result = {};
        try {
            result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
            setResponseData(result.data.data);
            // setTotalItems(result.data.total);
            // console.log(result.data.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    }

    const testmonisettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    rows: 1,
                    slidesPerRow: 1,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: false,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        // Add event listener to track window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (<>
        <main className='plp-page irfs-page pb-5 mt-6 mt-sm-5 bg-white irfshome'>
        <div className="hero-searchmain position-relative col-lg-8 col-12 align-self-center d-block d-lg-none" ref={searchInputRef}>
                <div className="text-start position-relative">
                    <div className="search-component">
                        {isMobile ? (
                            <SearchPopupIrfs buttonShow={true} iconShow={false} />
                        ) : (
                            <SearchInputIrfs irfs={isIrfsPath}/>
                        )}
                    </div>
                </div>
            </div>
            <Banner />
            {/* <div className="hero-searchmain position-relative col-lg-8 col-12 align-self-center d-none d-lg-block" ref={searchInputRef}>
                <div className="text-start position-relative">
                    <div className="search-component">
                        {isMobile ? (
                            <SearchPopupIrfs buttonShow={true} iconShow={false} />
                        ) : (
                            <SearchInputIrfs />
                        )}
                    </div>
                </div>
            </div> */}
            <Suspense fallback={<div>Loading...</div>}>
                <Overview />
            </Suspense>

            <section className="home-features feature-irfs bg-white" ref={sectionRef}>
                <div className='container project-container'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 className='sec-title hometitle text-md-start text-sm-center pt-md-0 fw-bold'>Discover Popular Projects</h2>
                        <div className="irfsbtn">
                            <a href={`${appAbsPath}/irfs/projects`} onClick={() => { createGa4Event("hp_explore_now", 'Explore Now', `Explore Now`) }}>View More</a>
                        </div>
                    </div>
                    <div className="top-projects-slider position-relative">
                        <Slider {...settings}>
                            {projects.map((project, index) => (
                                <ProjectCard
                                    key={index}
                                    project={project}
                                    isWishlistActive={isWishlistActive}
                                    showWishlist="yes"
                                    allowlazyload={true}
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>

            <section className='sbutract'>
                <div className='container'>
                    <div className='row sbutractrow'>
                        <div className='col-12 col-lg-6'>
                            <img data-src={Joshi} className="img-fluid joshi lazy" />
                        </div>
                        <div className='col-12 col-lg-6 d-none d-sm-block'>
                            <h1 className="sec-title fw-bold text-lg-end text-center">What Is IRFS?</h1>
                            <p class="sec-desc text-lg-end text-center">The Indian Realty Flash Sale (IRFS) is a limited-time digital event designed specifically for homebuyers and investors interested in Pune's real estate market. This exclusive Pune edition offers unparalleled opportunities to secure your dream home with special offers available only during the event period. Whether you're looking to settle in Pune or invest in its growing property market, IRFS provides a unique platform to access the best deals, all curated to deliver a transparent and efficient buying experience. Don't miss out on these exclusive offers, available only for a short time during the IRFS event.</p>
                            <a href="/blog/irfs-pune" class="irfs-btn2 d-block">Explore More</a>
                        </div>
                    </div>
                    <div className='col-12 col-xl-6 mt-sm-0 mt-4 d-block d-sm-none'>
                        <h2 className="sec-title fw-bold text-lg-end text-center">What Is IRFS?</h2>
                        <p class="sec-desc text-lg-end text-center">The Indian Realty Flash Sale (IRFS) is a limited-time digital event designed specifically for homebuyers and investors interested in Pune's real estate market. This exclusive Pune edition offers unparalleled opportunities to secure your dream home with special offers available only during the event period. Whether you're looking to settle in Pune or invest in its growing property market, IRFS provides a unique platform to access the best deals, all curated to deliver a transparent and efficient buying experience. Don't miss out on these exclusive offers, available only for a short time during the IRFS event.</p>
                        <a href="/blog/irfs-pune" class="irfs-btn2 d-block">Explore More</a>
                    </div>
                </div>
            </section>

            {/* <Suspense fallback={<div>Loading...</div>}>
                <ExploreProjectsLocalities irfs={true}/>
            </Suspense> */}

            {/*<section className='bg-white pt-5'>
                <h2 className='sec-title fw-bold text-center'>Testimonials</h2>
                <div className='testimoni-slider'>
                    <Slider {...testmonisettings}>
                        <div className='test-slide'>
                            <img src={Testimoni} className='img-fluid' />
                        </div>
                        <div className='test-slide'>
                            <img src={Testimoni} className='img-fluid' />
                        </div>
                        <div className='test-slide'>
                            <img src={Testimoni} className='img-fluid' />
                        </div>
                    </Slider>
                </div>
            </section>*/}

            {/* <section className='compare-section'>
                <div className='container'>
                    <div className='row comparesec-row'>
                        <div className='col-12 col-lg-6 position-relative'>
                            <img src={Sharman} className="img-fluid joshi2" />
                            <img src={House1} className="img-fluid house1" />
                            <img src={House2} className="img-fluid house2" />
                            <img src={House3} className="img-fluid house3" />
                        </div>
                        <div className='col-12 col-lg-6'>
                            <h2 className="sec-title text-white fw-bold text-lg-end text-center">Find Your Dream Home</h2>
                            <a href="/irfs/projects" class="irfs-btn2 d-block">Explore More</a>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='py-5 faq'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className="sec-title fw-bold text-center">FAQs</h2>
                            {/*<p class="sec-desc text-center">Why should i participate in it?</p>*/}
                        </div>
                    </div>
                </div>

                <Accordion defaultActiveKey="0" className='mainSectionAccordion' >
                    <Accordion.Item eventKey={0}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">What is IRFS?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">The Indian Reality Flash Sale (IRFS) is the biggest real estate sale with the best deals on top properties in Pune. Top properties, AI-powered search, property compare and exclusive deals on your dream home – is what IRFS brings you.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={1}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">Why should I participate in IRFS?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">IRFS presents homebuyers with an exclusive opportunity to buy their dream home. A transparent and hassle-free home buying experience with comprehensive project details. Choose from handpicked projects, compare your top choices, and get assured savings when you book during the event period.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={2}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">How do I participate in IRFS?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">To participate in IRFS all you need to sign up. Once signed up, discover properties, download comprehensive project brochures, and compare your top choices. Shortlist the ones you love, and get a Privilege Card that assures your ₹2.5 Lakhs* savings on your dream home.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={3}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">When will IRFS take place?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">IRFS will run from 7th September to 7th November 2024, with special offers and extended coupon redemption periods.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={4}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">How can developers participate in IRFS?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">Developers can register through the BeyondWalls portal, where they can list their projects and gain access to a wide audience of potential buyers.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={5}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">What benefits do developers get from participating in IRFS?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">Participating developers gain maximum visibility, access to exclusive marketing campaigns, and the opportunity to connect with a large pool of motivated buyers.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={6}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">What is the IRFS portal, and how does it work?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">The IRFS portal, powered by BeyondWalls, allows users to browse listed properties, compare projects, and access exclusive deals, all within an AI-powered platform.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={7}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">How is IRFS different from regular real estate events?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">IRFS offers a concentrated marketing effort, exclusive deals, and an AI-powered platform, making it a unique and impactful event for both developers and buyers.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={8}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">What kind of properties will be featured in IRFS?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">IRFS will feature a wide range of residential properties across various segments, catering to different buyer preferences and budgets.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={9}>
                        <Accordion.Header>
                            <div className="pdplist-background col-12">
                                <p className="fs-5 text-plp fw-bold m-0">Can homebuyers redeem their coupons after 31st October?</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="fs-5 text-plp m-0">No, coupons must be redeemed by 31st October 2024. After this date, they will no longer be valid.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </section>
        </main>
    </>);
}

export default IRFS;