import React, { useEffect, useState, useContext } from 'react';
import { executeLaravelFrontAPI, formatNumber, s3url, numberWithCommas, getMinMaxCarpets } from './../../Admin/Utils';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import defaultImg from '../../../images/default-floor-plan.png';
import { Icon } from '@iconify/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import EMICalculator from './EMICalculator';   

const ProjectDetailsLayout = ({ projectSlug }) => {
    const [project, setProject] = useState({});
    const [selectedConfiguration, setSelectedConfiguration] = useState(null);
    const [uniqueConfigurations, setUniqueConfigurations] = useState([]);
    const [carpetAreas, setCarpetAreas] = useState([]);
    const [selectedCarpet, setSelectedCarpet] = useState(null);
    const [selectedBalcony, setSelectedBalcony] = useState(null);
    const [selectedDryArea, setSelectedDryArea] = useState(null);
    const [selectedFinalPrice, setSelectedFinalPrice] = useState(null);

    const [configurationAsset, setConfigurationsAssetData] = useState('');
    const { priceFormSubmitted } = useContext(SearchVisibilityContext);

    useEffect(() => {
        if (projectSlug) {
            getProjectDetails();
        }
    }, [projectSlug]);

    useEffect(() => {
        if (uniqueConfigurations.length > 0) {
            const defaultConfigName = uniqueConfigurations[0].name;
            setSelectedConfiguration(defaultConfigName);
            const defaultCarpet = uniqueConfigurations[0].carpets[0];
            const defaultBalcony = uniqueConfigurations[0].balcony_area[0];
            const defaultDryArea = uniqueConfigurations[0].dry_area[0];
            const defaultFinalPrice = uniqueConfigurations[0].quoted_price[0];
            setSelectedCarpet(defaultCarpet);
            setSelectedBalcony(defaultBalcony);
            setSelectedDryArea(defaultDryArea);
            setSelectedFinalPrice(defaultFinalPrice);

        }
    }, [uniqueConfigurations]);
    useEffect(() => {
        const firstCarpet = uniqueConfigurations.find(config => config.name === selectedConfiguration)?.carpets
          .map((area, index) => ({ area, index })) // map to an array of objects so we can keep track of the original index
          .sort((a, b) => parseFloat(a.area) - parseFloat(b.area))[0]; // sort by area and get the first one
      
        if (firstCarpet) {
          handleCarpetClick(firstCarpet.area, uniqueConfigurations.find(config => config.name === selectedConfiguration)?.balcony_area[firstCarpet.index] || '0', uniqueConfigurations.find(config => config.name === selectedConfiguration)?.dry_area[firstCarpet.index] || '0',uniqueConfigurations.find(config => config.name === selectedConfiguration)?.quoted_price[firstCarpet.index] || '0');
        }
      }, [selectedConfiguration]);

    const getProjectDetails = async () => {
        try {
            const result = await executeLaravelFrontAPI(`project/${projectSlug}`, '', 'GET');
            // console.log('result:',result.data.id);
            const configurationsAssetData = await executeLaravelFrontAPI(`projectconfigurationassets/${result.data.id}`, '', 'GET', '');
            setConfigurationsAssetData(configurationsAssetData.data);

            const projectDetails = result.data;
            setProject(projectDetails);
            
            const uniqueConfigs = projectDetails.towers ? projectDetails.towers.flatMap(tower => tower.configurations.map(config => config.name)).filter((value, index, self) => self.indexOf(value) === index).sort() : [];
            // Initialize an array to store configurations with their carpets
            const configsWithCarpets = uniqueConfigs.map(name => ({ name, carpets: [], balcony_area: [], dry_area: [],quoted_price:[]}));
            
            
            // Iterate over each tower's configurations to collect carpet areas
            if (projectDetails.towers) {
                projectDetails.towers.forEach(tower => {
                    tower.configurations.forEach(config => {
                        const configIndex = configsWithCarpets.findIndex(c => c.name === config.name);
                        if (configIndex !== -1) {
                            // Add the carpet area to the corresponding configuration
                            configsWithCarpets[configIndex].carpets.push(config.carpet);
                            configsWithCarpets[configIndex].balcony_area.push(config.balcony_area);
                            configsWithCarpets[configIndex].dry_area.push(config.dry_area);
                            configsWithCarpets[configIndex].quoted_price.push(config.quoted_price);
                        }
                    });
                });
            }
    
            setUniqueConfigurations(configsWithCarpets);
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
    };
    // const extractBedroomCount = (configName) => {
    //     const regex = /(\d+)\s*BHK/;
    //     const match = configName.match(regex);
    //     return match ? parseInt(match[1]) : 0;
    // };

    const handleConfigurationClick = (configuration) => {
        setSelectedConfiguration(configuration);
        const selectedConfig = project.towers.flatMap(tower => tower.configurations).find(config => config.name === configuration);
        const carpets = selectedConfig ? [selectedConfig.carpet] : [];
        const balconies = selectedConfig ? [selectedConfig.balcony_area] : [];
        const dryAreas = selectedConfig ? [selectedConfig.dry_area] : [];
        const finalPrices = selectedConfig ? [selectedConfig.quoted_price] : [];
        setSelectedCarpet(carpets[0]);
        setSelectedBalcony(balconies);
        setSelectedDryArea(dryAreas);
        setSelectedFinalPrice(finalPrices);
    };

    const handleCarpetClick = (carpet, balconyArea, dryArea, finalPrices) => {
        setSelectedCarpet(carpet);
        setSelectedBalcony(balconyArea);
        setSelectedDryArea(dryArea);
        setSelectedFinalPrice(finalPrices);
    };
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow:<Icon icon="ri:arrow-left-s-line"  color="#000" className='prev-arrow' />,
		nextArrow:<Icon icon="ri:arrow-right-s-line"   color="#000" className='next-arrow' />  ,   
    };

    const [emiPopup, setEmiPopup] = useState(false);

    const handleEMIClick = () => {
        setEmiPopup(!emiPopup);
    };

    return (
        <div className="-details">
            <div className="layout-desc bg-white">
                <div className="configurations-listing tab__list overflow-auto">
                    {uniqueConfigurations.map((config, index) => (
                        <div key={index} className={`text-uppercase tab__item me-2 me-md-3 config-item ${selectedConfiguration === config.name ? 'is--active' : ''}`} onClick={() => handleConfigurationClick(config.name)}>
                            {config.name}
                        </div>
                    ))}
                </div>

                {selectedConfiguration && (
                    <div className="carpet-areas mb-4">
                        {/* <h3>Carpet Areas for {selectedConfiguration}</h3> */}
                        <div className="carpet-listing">
                            {/* <div className="tab__list tab__list_sqft overflow-auto mb-4">
                                {
                                uniqueConfigurations.find(config => config.name === selectedConfiguration)?.carpets
                                    .map((area, index) => ({ area, index })) // map to an array of objects so we can keep track of the original index
                                    .sort((a, b) => parseFloat(a.area) - parseFloat(b.area)) // sort by area
                                    .map(({ area, index }) => ( // map back to JSX
                                    <div 
                                        key={index} 
                                        className={`tab__item tab__sub__item ${selectedCarpet === area ? 'is--active' : ''}`} 
                                        onClick={() => handleCarpetClick(area, uniqueConfigurations.find(config => config.name === selectedConfiguration)?.balcony_area[index] || '0', uniqueConfigurations.find(config => config.name === selectedConfiguration)?.dry_area[index] || '0',uniqueConfigurations.find(config => config.name === selectedConfiguration)?.quoted_price[index] || '0')}>
                                        {parseFloat(area).toFixed(0)} sq.ft
                                    </div>
                                    ))
                                }
                            </div> */}
                        </div>
                    </div>
                )}
                {selectedCarpet && (
                    <div className="selected-carpet-details">
                        <div className="d-flex flex-column flex-md-row justify-content-between col-md-11 m-0 m-md-auto gap-4 carpet-agreement-div">
                            <div className="d-flex flex-column">
                                <h5 className="text-secondary">{selectedConfiguration}</h5>
                                <span className="text-property">Carpet Area : {parseFloat(selectedCarpet).toFixed(4)} sq.ft</span>
                                {selectedBalcony && parseFloat(selectedBalcony) > 0 && (
                                    <span className="text-property">Balcony : {parseFloat(selectedBalcony).toFixed(0)} sq.ft</span>
                                )}
                                {selectedDryArea && parseFloat(selectedDryArea) > 0 && (
                                    <span className="text-property">Dry Area : {parseFloat(selectedDryArea).toFixed(0)} sq.ft</span>
                                )}
                            </div>
                            <div className="d-flex flex-column align-items-start align-items-md-end">
                                <h5 className="text-secondary">₹ 
                                {formatNumber(selectedFinalPrice)}
                                </h5>
                                <span className="text-property emi-field d-flex" onClick={() => handleEMIClick()} data-emi="2717300">EMI starts at &nbsp;<EMICalculator loanPrice={selectedFinalPrice} showEMI={emiPopup} /> /month</span>
                            </div>
                        </div>
                        {configurationAsset && configurationAsset.length >= 1 ? (
                            configurationAsset.some(asset => asset.carpet === selectedCarpet) ? (
                                configurationAsset.length < 2 ? (
                                <div className="d-flex flex-column align-items-center">
                                     <img 
                                        src={`${s3url}/project-assets${configurationAsset[0].url}`} 
                                        className="img-fluid mt-4" 
                                    />
                                </div>
                                ):(
                                <Slider {...settings}>
                                {configurationAsset.map((asset, index) => (
                                    <div key={index} data-index-id={index} className="d-flex flex-column align-items-center">
                                    {asset.carpet === selectedCarpet ? (
                                        <img 
                                        src={`${s3url}/project-assets${asset.url}`} 
                                        alt={asset.name} 
                                        className="img-fluid mt-4" 
                                    />
                                    ) : null}
                                </div>
                                ))}
                                </Slider>
                                )
                            ) : (
                                <div className="d-flex flex-column align-items-center">
                                {/* <img 
                                    src={defaultImg}
                                    alt="Default Floor Plan" 
                                    className="img-fluid" 
                                /> */}
                                <p className='mt-2'><Icon icon="fa6-regular:face-sad-tear"  color={"#667486"} /> Floor plan that you’ve requested is currently unavailable</p>
                                </div>
                            )
                            ) : (
                            <div className="d-flex flex-column align-items-center mt-5">
                                {/* <img 
                                src={defaultImg}
                                alt="Default Floor Plan" 
                                className="img-fluid" 
                                /> */}
                                 <p className='mt-2'><Icon icon="fa6-regular:face-sad-tear" className='me-2 align-middle'  color={"#667486"} /> Floor plan that you’ve requested is currently unavailable</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectDetailsLayout;
