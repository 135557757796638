import React, {useState, useContext } from 'react';
import { userToken, appAbsPath , createGa4Event} from '../../Admin/Utils';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import html2canvas from 'html2canvas';
import bwLogo from '../../../Assets/img/beyondwalls-logoirfs.svg';
import cStar from '../../../Assets/img/cstar.svg';
import Celleft from '../../../Assets/img/celleft.png';
import IrfsLogo from '../../../Assets/img/irfscuponlogo.png';
import Celright from '../../../Assets/img/celright.png';
import IrfsLogobg from '../../../Assets/img/irfslogobg.png';
import Sirfs from '../../../Assets/img/sharmanirfs.png';

const CouponTag = ({ couponCode, offerName, projectName, userName, userPhone }) => {
    const { downloadCouponBtn, couponData, setDownloadCouponBtn } = useContext(SearchVisibilityContext);
    const [thankYou, setThankYou] = useState(false); // Thank You step state


    const currentDate = new Date();
    // Add 3 months to the current date
    const expiryDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3));

    // Format the date (e.g., 30th Oct 2024)
    const formattedExpiryDate = expiryDate.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
    const downloadCouponpdf = () => {
        const input = document.querySelector('.coupon-wrapper');
        const excludeDiv = input.querySelector('.exclude-this');
        createGa4Event('download_coupon', `download_coupon`, `${projectName}`);

        if (!input) {
            console.error('Coupon box not found');
            return;
        }

        if (excludeDiv) {
            excludeDiv.style.display = 'none';
        }

        html2canvas(input, { useCORS: false, scale: 2, logging: false, allowTaint: false })
            .then((canvas) => {
                // Restore the excluded div's visibility
                if (excludeDiv) {
                    excludeDiv.style.display = '';
                }

                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = 'coupon.png';
                link.click();
                setDownloadCouponBtn(false);
                setThankYou(false);

                if (!userToken) {
                    setTimeout(() => {
                        window.location.href = `${appAbsPath + window.location.href}`;
                    }, 3000);
                }
            })
            .catch((error) => {
                console.error("Could not download the coupon:", error);
                // Restore the excluded div's visibility in case of error
                if (excludeDiv) {
                    excludeDiv.style.display = '';
                }
            });
    };
    return (
        <div className="coupon-wrapper">
            <div className="thank-you-step">
                <div className="cuponwrap">
                    <div className="cuponheader">
                        <div className="expidiv">
                            <p className="cuponhead exphead">BOOK BEFORE</p>
                            <p className="expdate">{formattedExpiryDate}</p>
                        </div>
                        <img src={bwLogo} alt="IRFS Logo" className="irfs-logobw" />
                    </div>
                    <div className="cuponbody">
                        <div className="cuponheaddiv">
                            <p className="tokenid">TOKEN ID:</p>
                            <p className="tokenidnum">{couponCode}</p>
                        </div>
                        <div className="offerdiv">
                            <div className="text-offerdiv">
                                <img src={cStar} alt="Star" className="cstar1" />
                                <span className="text-offer">{offerName}</span>
                                <img src={cStar} alt="Star" className="cstar1" />
                            </div>
                            <p className="cupon-proj">Project: <span>{projectName}</span></p>
                            <div className="logodiv">
                                <div className="logodivflex">
                                    <img src={Celleft} alt="Logo Left" className="celleft" />
                                    <img src={IrfsLogo} alt="IRFS Logo" className="irfs-logo" />
                                    <img src={Celright} alt="Logo Right" className="celright" />
                                </div>
                                <img src={IrfsLogobg} alt="IRFS Logo Background" className="irfslogobg" />
                            </div>
                            <div className="text-offerdiv1">
                                <img src={cStar} alt="Star" className="cstar1" />
                                <img src={cStar} alt="Star" className="cstar1" />
                            </div>
                        </div>
                        <div className="cuponfooter">
                            <div>
                                <p className="uhead">Name: <span>{userName}</span></p>
                                <p className="uhead">Ph No: <span>{userPhone}</span></p>
                            </div>
                            <img src={Sirfs} alt="IRFS Logo" className="sirfs" />
                        </div>
                    </div>
                    <div className="download-btn exclude-this">
                        <button type="button" onClick={downloadCouponpdf} className="btn sc register-btn px-4 fs-6 download-cupon-btn">
                            <span className="signin text-uppercase">Download Free Coupon</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CouponTag;
