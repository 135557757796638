// CouponButtons.js
import React, { useState, useContext } from 'react';
import { executeLaravelAPI } from './../../Admin/Utils';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import { showToastMessage, createGa4Event } from './../../Admin/Utils';

const CouponButtons = ({ project, parsedUserData, buttonText = 'Download Coupon' }) => {
  const [couponDetails, setCouponDetails] = useState({});
  const [coupon, setCoupon] = useState(false);
  const { setDownloadCouponBtn, setCouponData } = useContext(SearchVisibilityContext);

  // Function to handle not logged-in users
  const handleNotLogin = (btnClick, project = '', offer_id = '') => {
    document.getElementById('overlay').style.display = 'block';
    if (btnClick === "Download Coupon") {
      document.getElementById('overlay').classList.add('irfsoverlay');
      document.getElementById('login_title').textContent = "Your Coupon is ready, login to download";
      setDownloadCouponBtn(true);
      setCouponData({ project_id: project.id, offer_id: parseInt(offer_id) });
    }
  };

  // Function to handle logged-in users downloading the coupon
  const handleLoginCoupon = async (btnClick, project = '', offer_id = '') => {
    document.getElementById('overlay').style.display = 'block';
    const couponrequiredData = { pid: project.id };
    setCouponData({ project_id: project.id, offer_id: parseInt(offer_id) });

    try {
      const result = await executeLaravelAPI(`getcouponbyid`, couponrequiredData, 'GET', localStorage.getItem('bwToken'));
      if (result.status === 200 && result.is_empty === false) {
        setDownloadCouponBtn(true);
      } else {
        setDownloadCouponBtn(true);
      }
    } catch (error) {
      console.error('Error fetching coupon data:', error);
    }
  };

  // Function to redeem coupon
  const handleRedeemCoupon = async () => {
    try {
      const couponrequiredData = { offer_id: couponDetails.offer_id, project_id: couponDetails.project_id };

      const couponDetailsResponse = await executeLaravelAPI('add-couponcode', couponrequiredData, 'POST', localStorage.getItem('bwToken'));

      if (couponDetailsResponse.status === 200) {
        showToastMessage('Coupon Redeemed', 'top-right', 'success');
        setCoupon(true);
        setCouponDetails(couponDetailsResponse.data);
        setDownloadCouponBtn(false);
      }
    } catch (error) {
      console.error('Error redeeming coupon:', error);
    }
  };

  return (
    <div className='btnflex'>
      {Array.isArray(project?.offers) && project?.offers.map((offer, offersIndex) => (
        <div key={offersIndex}>
          {parsedUserData !== null ? (
            <button 
              data-offer-id={offer.id} 
              className="btn cupon-btn btn-sm" 
              onClick={() => {handleLoginCoupon("Download Coupon", project, offer.id);createGa4Event('download_coupon', `download_coupon`, `${project.name}`);}}
            >
              {buttonText}
            </button>
          ) : (
            <button 
              className="btn cupon-btn btn-sm" 
              data-offer-id={offer.id} 
              onClick={() => {handleNotLogin("Download Coupon", project, offer.id);createGa4Event('download_coupon', `download_coupon`, `${project.name}`)}}
            >
              {buttonText}
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default CouponButtons;
