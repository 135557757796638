import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const SubmitButton = ({ variant, type, className, label }) => {
  // const [isClicked, setIsClicked] = useState(false);
  // const handleClick = () => {
  //   setIsClicked(!isClicked);
  // };
  // const updatedClassName = isClicked ? `${className}` : className;
  //console.log(updatedClassName);
  return (
    <Button variant={variant} type={type} className={className}>{label}<span className="btn__dots justify-content-center"><i></i><i></i><i></i></span></Button> 
  );
};
export default SubmitButton;
