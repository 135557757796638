import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import customMarkerRed from '../../../images/map-pin-red.svg';
import customMarker from '../../../images/map-pin.svg';
import direction from '../../../images/directions.svg';
// import { Icon } from '@iconify/react';
import { isMobileDevice, s3url, formatNumber, getMinMaxCarpets, getMinMaxPrice, getSortBHKConf, appAbsPath} from './../../Admin/Utils';
import defaultGallery from '../../../images/default-corporate-image.webp';

const isMobile = isMobileDevice();
const EsriLeafletMapDetailsPage = ({ latitude, longitude, height, project }) => {
  const mapRef = useRef(null); // Ref for the map container
  const mapInstance = useRef(null); // Ref for the map instance
  const [activeAmenity, setActiveAmenity] = useState(null);
  const [mapCenter, setMapCenter] = useState([latitude, longitude]);
  const [markers, setMarkers] = useState([]); // State to store markers

  const customIcon = L.icon({
    iconUrl: customMarkerRed,
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -40]
  });
  
  const customIconSmall = L.icon({
    iconUrl: customMarker,
    iconSize: [30, 30],
    iconAnchor: [25, 50],
    popupAnchor: [0, -40]
  });

  useEffect(() => {
    if (!mapRef.current) return;

    if (mapInstance.current) {
      mapInstance.current.remove(); // Remove the existing map instance before creating a new one
    }

    mapInstance.current = L.map(mapRef.current, {
      center: [latitude, longitude],
      zoom: 13,
      scrollWheelZoom: false
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(mapInstance.current);
    updateMarkers();
  }, [latitude, longitude, project]);

  useEffect(() => {
    updateMarkers();
  }, [activeAmenity]);
  const updateMarkers = () => {
    if (!mapInstance.current) return;

    clearMarkers(); // Clear existing markers before adding new ones
    mapInstance.current.setView([latitude, longitude], 13); // Center the map on the new coordinates

    const imageUrl = project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0
      ? encodeURI(`${s3url}/project-assets${project.gallery[0].url}`)
      : defaultGallery;
    const minMaxPrice = getMinMaxPrice(project);
    const minPrice = minMaxPrice.minPrice !== null ? formatNumber(minMaxPrice.minPrice.toFixed(2)) : 'N/A';
const maxPrice = minMaxPrice.maxPrice !== null ? formatNumber(minMaxPrice.maxPrice.toFixed(2)) : 'N/A';
    const priceRange = `₹${minPrice} - ${maxPrice}`;

    const minMaxCarpets = getMinMaxCarpets(project);
    const minCarpet = minMaxCarpets.minCarpet.toFixed(0);
    const maxCarpet = minMaxCarpets.maxCarpet.toFixed(0);

    // Add a marker for the project location
    // <h4 class='mb-2 mt-2 fw-bold'>${priceRange}</h4>
    //       ${getSortBHKConf(project)}, ${minCarpet} - ${maxCarpet} sq.ft.
    L.marker([latitude, longitude], { icon: customIcon }).addTo(mapInstance.current)
      .bindPopup(`<div class='inner-map-popup' id=${project.id}>
        <img src=${imageUrl} class='project-img' />
        <div class='leaflet-popup-content'>
          
          <a href='${appAbsPath}/project/${project.slug}' onClick='createGa4Event("project_view", "Project View", "${project.name}")' class='text-secondary text-capitalize text-decoration-none'>
            <h6 class="m-0">${project.name}</h6>
          </a>
          <p class='mb-0 developer-name'>By <span>${project.developer.name}</span>, ${project?.address?.location}, ${project?.address?.city}</p>
        </div>
      </a>`);

    fetchNearbyAmenities(latitude, longitude, activeAmenity);
  };

  const fetchNearbyAmenities = (lat, lon, amenitiesValue) => {
    fetch(`https://overpass-api.de/api/interpreter?data=[out:json];(node["amenity"=${amenitiesValue}](around:3000,${lat},${lon}););out;`)
      .then(response => response.json())
      .then(data => {
        const newMarkers = data.elements.map(amenity => {
          const marker = L.marker([amenity.lat, amenity.lon], { icon: customIconSmall }).addTo(mapInstance.current);
          marker.bindPopup(`<p class="px-3 map-inner-info"><b>${amenity.tags.name}</b></p>`);
          return marker;
        });
        setMarkers(newMarkers);
      })
      .catch(error => console.error('Error fetching nearby amenities:', error));
  };

  const clearMarkers = () => {
    markers.forEach(marker => marker.remove());
    setMarkers([]);
  };

  const handleAmenitiesSet = (amenitiesValue) => {
    setActiveAmenity(amenitiesValue); // Change active amenity and update map
  };
  

  return (
    <div>
      <div ref={mapRef} style={{ height: isMobile ? "250px" : height, width: '100%' }}></div>
      <div className='col-12 d-flex amenitiesNearBywrap flex-column mb-4'>
        <p><b>Location & nearby places:</b></p>
        <div className=''>
          <div className='amenitiesNearBy d-block d-md-flex align-items-center justify-content-between'>
            <ul className='d-flex'>
              {/* <li className={activeAmenity === 'community_centre' ? 'active' : ''} onClick={() => handleAmenitiesSet('community_centre')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="23.998" height="24" viewBox="0 0 23.998 24">
                  <path id="cars" d="M24,18.079a12.978,12.978,0,0,0-.757-4.373l-.853-2.388A5.01,5.01,0,0,0,17.681,8H13.319A5.01,5.01,0,0,0,8.61,11.318l-.853,2.388C6.9,16.223,6.209,20,9,21.5V22a2,2,0,0,0,4,0h5a2,2,0,0,0,4,0v-.5A3.923,3.923,0,0,0,24,18.079ZM13.319,10h4.362a3,3,0,0,1,2.825,1.991L21.224,14H9.776l.718-2.009A3,3,0,0,1,13.319,10Zm6.76,10H10.921C8.749,19.949,8.84,17.569,9.2,16H11v1a1,1,0,0,0,2,0V16h5v1a1,1,0,0,0,2,0V16h1.8C22.16,17.569,22.25,19.95,20.079,20ZM4,9V8H2.2c-.358,1.569-.448,3.95,1.723,4H5a1,1,0,0,1,1,1v1a2,2,0,0,1-4,0v-.5C-.792,11.994-.1,8.225.757,5.706L1.61,3.318A5.01,5.01,0,0,1,6.319,0h4.362A5.01,5.01,0,0,1,15.39,3.318l.481,1.346a1,1,0,1,1-1.884.672l-.481-1.345A3.005,3.005,0,0,0,10.681,2H6.319A3.005,3.005,0,0,0,3.494,3.991L2.776,6H8A1,1,0,0,1,8,8H6V9A1,1,0,0,1,4,9Z" transform="translate(-0.002)" fill="#667486"/>
                </svg>
                <p>Commute</p>
              </li> */}
              <li className={activeAmenity === 'school' ? 'active' : ''} onClick={() => handleAmenitiesSet('school')}>
                {/* <Icon icon="emojione-monotone:school" width={30} height={30} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22">
                <path id="graduation-cap" d="M14.12,1.627a3.9,3.9,0,0,0-4.24,0L0,8l4,2.58v9.834l.293.293C4.387,20.8,6.647,23,12,23s7.613-2.2,7.707-2.293L20,20.414V10.58l2-1.29V20h2V8ZM18,19.516A10.557,10.557,0,0,1,12,21a10.558,10.558,0,0,1-6-1.483V11.87l3.88,2.5a3.892,3.892,0,0,0,4.24,0L18,11.87Zm-4.964-6.824a1.9,1.9,0,0,1-2.072,0L3.69,8l7.274-4.692a1.9,1.9,0,0,1,2.072,0L20.31,8Z" transform="translate(0 -1)" fill="#667486"/>
              </svg>

                <p>Schools</p>
              </li>
              <li className={activeAmenity === 'restaurant' ? 'active' : ''} onClick={() => handleAmenitiesSet('restaurant')}>
              {/* <Icon icon="healthicons:hospital-outline" width={32} height={32} /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="23.984" height="24" viewBox="0 0 23.984 24">
                <path id="hamburger" d="M24,14.006a2.981,2.981,0,0,0-1-2.219V10A10.011,10.011,0,0,0,13,0H11A10.011,10.011,0,0,0,1,10v1.837A3.137,3.137,0,0,0,1,16.4V17a7.008,7.008,0,0,0,7,7h8a7.008,7.008,0,0,0,7-7v-.774a2.981,2.981,0,0,0,1-2.22ZM2.812,15.158a1.1,1.1,0,0,1,.282-2.152H21a1,1,0,0,1,0,2H17c-1.956,0-3.979.788-6.364,2.481a2.867,2.867,0,0,1-3.271,0c-.228-.162-.447-.331-.667-.5a7.5,7.5,0,0,0-3.886-1.829ZM3,10a8.009,8.009,0,0,1,8-8h2a8.009,8.009,0,0,1,8,8v1.006l-18,.01ZM16,22H8a4.994,4.994,0,0,1-4.975-4.755,6.24,6.24,0,0,1,2.454,1.327c.239.185.479.369.729.546a4.893,4.893,0,0,0,5.584,0A9.385,9.385,0,0,1,17,17.006h4A5,5,0,0,1,16,22ZM9,5a1,1,0,1,1,1,1A1,1,0,0,1,9,5ZM6,8A1,1,0,1,1,7,9,1,1,0,0,1,6,8Z" transform="translate(-0.016)" fill="#667486"/>
              </svg>

                <p>Restaurants</p>
              </li>
              <li className={activeAmenity === 'hospital' ? 'active' : ''} onClick={() => handleAmenitiesSet('hospital')}>
                {/* <Icon icon="fluent:building-bank-20-regular" width={32} height={32} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path id="hospital" d="M20,6.1V5a5.006,5.006,0,0,0-5-5H9A5.006,5.006,0,0,0,4,5V6.1A5.009,5.009,0,0,0,0,11v8a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V11A5.009,5.009,0,0,0,20,6.1ZM2,19V11A3,3,0,0,1,4,8.172V21.828A3,3,0,0,1,2,19ZM6,5A3,3,0,0,1,9,2h6a3,3,0,0,1,3,3V22H6ZM22,19a3,3,0,0,1-2,2.828V8.172A3,3,0,0,1,22,11ZM11,14a1,1,0,0,1-1,1H9a1,1,0,0,1,0-2h1A1,1,0,0,1,11,14Zm5,0a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h1A1,1,0,0,1,16,14Zm-5,4a1,1,0,0,1-1,1H9a1,1,0,0,1,0-2h1A1,1,0,0,1,11,18Zm5,0a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h1A1,1,0,0,1,16,18ZM13,9a1,1,0,0,1-2,0V8H10a1,1,0,0,1,0-2h1V5a1,1,0,0,1,2,0V6h1a1,1,0,0,1,0,2H13Z" fill="#667486"/>
                </svg>

                <p>Hospitals</p>
              </li>
              {/* <li className={activeAmenity === 'marketplace' ? 'active' : ''} onClick={() => handleAmenitiesSet('marketplace')}>
                <Icon icon="material-symbols-light:local-mall-outline-sharp" width={32} height={32} />
                <p>Supermarket</p>
              </li> */}
              {/* <li className={activeAmenity === 'groceries' ? 'active' : ''} onClick={() => handleAmenitiesSet('groceries')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24.008" height="24.01" viewBox="0 0 24.008 24.01">
                  <path id="salad" d="M23.194,11.6a3.942,3.942,0,0,0-2.143-1.447,21.606,21.606,0,0,0,.922-3.731,3.008,3.008,0,0,0-3.4-3.392c-.126.017-.5.072-1.011.171A4.979,4.979,0,0,0,18,.968a1,1,0,1,0-2,.064,3.021,3.021,0,0,1-.289,1.4,3.894,3.894,0,0,0-.611-1.08A4.006,4.006,0,0,0,11.5.034,3.8,3.8,0,0,0,9.5.8,3.939,3.939,0,0,0,7.233.01,3.949,3.949,0,0,0,4.348.887,4.028,4.028,0,0,0,3,3.782a4.1,4.1,0,0,0,.73,2.466,4.951,4.951,0,0,0-1.7,4.288A3.967,3.967,0,0,0,.164,15.15a14.749,14.749,0,0,0,3.707,6.316A8.922,8.922,0,0,0,10.139,24h3.722a8.918,8.918,0,0,0,6.267-2.534,14.741,14.741,0,0,0,3.708-6.316A4,4,0,0,0,23.194,11.6ZM18.851,5.01A1.013,1.013,0,0,1,19.99,6.152,20.024,20.024,0,0,1,18.975,10H16.414l1.293-1.293a1,1,0,1,0-1.414-1.414L13.586,10H12.037a3.428,3.428,0,0,1,.976-2.9C13.44,6.669,15.077,5.5,18.851,5.01ZM5,3.849a2.026,2.026,0,0,1,.665-1.457A2.035,2.035,0,0,1,7.1,2.006a1.748,1.748,0,0,1,1.328.531A1.438,1.438,0,0,0,9.5,3.016h0a1.44,1.44,0,0,0,1.068-.482,1.64,1.64,0,0,1,1.156-.513,2.031,2.031,0,0,1,1.828.6A2.118,2.118,0,0,1,14,3.985c0,.065,0,.207,0,.207a6.446,6.446,0,0,0-3.228,2.563,4.916,4.916,0,0,0-5.284-1.5A2.064,2.064,0,0,1,5,3.849ZM4,10a3,3,0,1,1,6,0Zm17.916,4.589a12.919,12.919,0,0,1-3.2,5.46A6.9,6.9,0,0,1,13.861,22H10.139a6.909,6.909,0,0,1-4.857-1.951,12.917,12.917,0,0,1-3.2-5.46A2.019,2.019,0,0,1,2.406,12.8,1.978,1.978,0,0,1,4,12H20a1.978,1.978,0,0,1,1.6.8,2.019,2.019,0,0,1,.316,1.789Z" transform="translate(0.006 0.01)" fill="#667486"/>
                </svg>

                <p>Groceries</p>
              </li> */}
              <li className={activeAmenity === 'food_court' ? 'active' : ''} onClick={() => handleAmenitiesSet('food_court')}>
                {/* <Icon icon="material-symbols-light:local-cafe-outline-rounded" width={32} height={32} /> */}
                <svg id="shopping-cart" xmlns="http://www.w3.org/2000/svg" width="23.411" height="24" viewBox="0 0 23.411 24">
                  <path id="Path_88343" data-name="Path 88343" d="M22.713,4.077A2.993,2.993,0,0,0,20.41,3H4.242L4.2,2.649A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.82-2h11.92a5,5,0,0,0,4.921-4.113l.785-4.354a2.994,2.994,0,0,0-.65-2.456ZM21.4,6.178l-.786,4.354A3,3,0,0,1,17.657,13H5.419L4.478,5H20.41a1,1,0,0,1,.99,1.178Z" fill="#667486"/>
                  <circle id="Ellipse_382" data-name="Ellipse 382" cx="2" cy="2" r="2" transform="translate(5 20)" fill="#667486"/>
                  <circle id="Ellipse_383" data-name="Ellipse 383" cx="2" cy="2" r="2" transform="translate(15 20)" fill="#667486"/>
                </svg>

                <p>Shopping</p>
              </li>
              {/* <li className={activeAmenity === 'park' ? 'active' : ''} onClick={() => handleAmenitiesSet('park')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path id="tree-deciduous" d="M18.483,5.157h0a1.034,1.034,0,0,1-.7-.772,6,6,0,0,0-11.56,0,1.034,1.034,0,0,1-.7.772A7,7,0,0,0,0,12.215,7.183,7.183,0,0,0,7.251,19H11v4a1,1,0,0,0,2,0V19h2.784c4.641,0,8.1-2.854,8.213-6.785a7,7,0,0,0-5.514-7.058Zm3.515,7C21.912,15.008,19.357,17,15.784,17H13V15.414l3.707-3.707a1,1,0,1,0-1.414-1.414L13,12.586V9a1,1,0,0,0-2,0v3.586L8.707,10.293a1,1,0,0,0-1.414,1.414L11,15.414V17H7.251A5.17,5.17,0,0,1,2,12.154,5,5,0,0,1,5.938,7.112a3,3,0,0,0,2.208-2.19,4,4,0,0,1,7.706,0,3,3,0,0,0,2.208,2.19A5,5,0,0,1,22,12.154Z" transform="translate(0)" fill="#667486"/>
                </svg>

                <p>Park</p>
              </li> */}
              <li className={activeAmenity === 'bank' ? 'active' : ''} onClick={() => handleAmenitiesSet('bank')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24.002" height="24" viewBox="0 0 24.002 24">
                  <path id="piggy-bank" d="M18,12a1,1,0,1,1-1-1A1,1,0,0,1,18,12ZM7,5A1,1,0,0,0,8,4a2,2,0,0,1,4,0,1,1,0,0,0,2,0A4,4,0,0,0,6,4,1,1,0,0,0,7,5Zm17,8v2a1.936,1.936,0,0,1-1.568,1.954A8.021,8.021,0,0,1,19,20.908V21a3,3,0,0,1-5.829,1H10.828A3,3,0,0,1,5,21v-.079A7.967,7.967,0,0,1,1.016,13.47a7.694,7.694,0,0,1,.7-2.756A3,3,0,0,1,3,5,1,1,0,0,1,3,7a1,1,0,0,0-.177,1.984A8.381,8.381,0,0,1,9.264,6h5.363a5.663,5.663,0,0,1,3.927-2.946,1.97,1.97,0,0,1,1.681.374A2,2,0,0,1,21,5V8.719a8.055,8.055,0,0,1,1.432,2.327A1.935,1.935,0,0,1,24,13Zm-2,0h-.294a1,1,0,0,1-.958-.715,6.038,6.038,0,0,0-1.463-2.477A1,1,0,0,1,19,9.11V5a3.624,3.624,0,0,0-2.764,2.335.994.994,0,0,1-.99.684C15.178,8.02,9.266,8,9.266,8a6.153,6.153,0,0,0-6.253,5.6A5.979,5.979,0,0,0,6.43,19.415a1,1,0,0,1,.57.9V21a1,1,0,0,0,2,0,1,1,0,0,1,1-1h4a1,1,0,0,1,1,1,1,1,0,0,0,2,0v-.694a1,1,0,0,1,.569-.9,6.01,6.01,0,0,0,3.179-3.688A1,1,0,0,1,21.706,15H22V13Z" transform="translate(0.002)" fill="#667486"/>
                </svg>

                <p>Bank</p>
              </li>
            </ul>
            <div class="map-card-btn-wrapper">
              <a href={`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`} target="_blank" class='btn btn-primary map-card-btn'>
              {/* {console.log(latitude, longitude)} */}
                 Get Direction&nbsp;
                <img src={`${direction}`} class='direction-img' width="30px" height="30px"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsriLeafletMapDetailsPage;
