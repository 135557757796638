import React, { useEffect, useState, useRef } from 'react';
import { userToken, executeLaravelAPI, showToastMessage, buttonLoadingClass } from '../../Utils';
import { Form } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import SubmitButton from '../../shared/SubmitButton';

const ProjectEditBasicDetails = ({ selectedProjectId }) => {
    const buttonClass=useRef('theme-btn btn btn-success');
    const pageDetails={'pagetitle':'Edit Project','pageslug':'edit-project'};
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [buttonIcon, setButtonIcon] = useState({});
    
    const [projectBasicData, setEditedItem] = useState({
        id: '',
        name: '',
        project_type: '',
        project_status : '',
        total_units : '',
        per_sqft_price : '',
        completion_timestamp : '',
        possession_date_readable :'',
        project_size : '',
        slug : ''
    });
    
    useEffect(() => {
        document.title = pageDetails.pagetitle;
        const getProjects = async () => {
            try {
                const result = await executeLaravelAPI(`project/view/${selectedProjectId}`, '', 'GET', userToken);
                const dataProjectDetails = result.data;
                //setEditedItem(dataProjectDetails); // Set the projectBasicData state
                setEditedItem({
                    id: dataProjectDetails.id,
                    name: dataProjectDetails.name,
                    project_type: dataProjectDetails.project_type,
                    project_status : dataProjectDetails.project_status,
                    total_units : dataProjectDetails.total_units,
                    completion_timestamp : dataProjectDetails.completion_timestamp,
                    possession_date_readable :dataProjectDetails.possession_date_readable,
                    project_size : dataProjectDetails.project_size,
                    per_sqft_price : dataProjectDetails.per_sqft_price,
                    slug : dataProjectDetails.slug
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (selectedProjectId) {
            getProjects();
        }
    }, [selectedProjectId]);

    const toggleDetails = (projectId, projectAddressId, projectDeveloperId) => {
        // console.log('Project ID:- '+projectId);
        // console.log('Project Address ID:- '+projectAddressId);
        // console.log('Project Developer ID:- '+projectDeveloperId);
    };

    const generateSlug = async (projectId, projectName) => {
        try {
            const result = await executeLaravelAPI(`project/generate-slug/${projectId}`, {name: projectName}, 'POST', userToken);
            const dataProjectDetails = result.data;
            setEditedItem({
                ...projectBasicData,
                slug: dataProjectDetails
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedItem({
          ...projectBasicData,
          [name]: value,
        });
      };

    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle empty or null values
        //console.log("dated:- "+dateString);
        const dateObject = new Date(dateString);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
        const year = dateObject.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const updateProjectBasicDetails = async (e) => {
        e.preventDefault();
        try {
            document.getElementsByClassName('theme-btn').disabled = true;
            setButtonClass(`${buttonClass.current} ${buttonLoadingClass}`);
            const updatedProjectBasicDetailsData = await executeLaravelAPI(`project/edit/${selectedProjectId}`, projectBasicData, 'PUT', userToken);
            if (updatedProjectBasicDetailsData.status === 200) {
                showToastMessage('Basic Details are updated successfully', 'top-right', 'success');
            } else {
                showToastMessage('Basic Details are not updated', 'top-right', 'error');
            }
            setButtonClass(`${buttonClass.current}`);
            document.getElementsByClassName('theme-btn').disabled = false;
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };
    

    return (
        <form onSubmit={updateProjectBasicDetails} className='projectForm'>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Project Name">
                                <Form.Control placeholder="Project Name" type="text" name='name' value={projectBasicData.name || ''} onChange={handleInputChange} disabled={false} readOnly={false} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                {/* <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Carpet Area">
                                <Form.Control placeholder="Carpet Area" type="text" name='max_carpet_area' value="" onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div> */}
                
                {/* <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="RERA">
                                <Form.Control placeholder="RERA" type="text" name='rera_registration_no' value={projectBasicData.rera_registration_no || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div> */}

                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Select Project Type">
                                <select name="project_type" className="form-select" value={projectBasicData.project_type} onChange={handleInputChange} >
                                    <option>--Select Type--</option>
                                    <option value="Residential">Residential</option>
                                    <option value="Commercial">Commercial</option>
                                    <option value="Industrial">Industrial</option>
                                    <option value="Mixed(Plot & Buildings)">Mixed(Plot & Buildings)</option>
                                    <option value="Others">Others</option>
                                </select>
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Project Status">
                                <select name="project_status" className="form-select" value={projectBasicData.project_status} onChange={handleInputChange} >
                                    <option value="">--Select Project Status--</option>
                                    <option value="New Project">New Project</option>
                                    <option value="New Launch">New Launch</option>
                                    <option value="Under Construction">Under Construction</option>
                                    <option value="Ready To Move">Ready To Move</option>
                                </select>
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Total Unit">
                                <Form.Control placeholder="Total Unit" type="text" name='total_units' value={projectBasicData.total_units || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Price/sqft">
                                <Form.Control placeholder="Price/sqft" type="text" name='per_sqft_price' value={projectBasicData.per_sqft_price || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>

                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Project Size">
                                <Form.Control placeholder="Project Size (in Acre)" type="text" name='project_size' value={projectBasicData.project_size || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                        <FloatingLabel controlId="floatingInput" label="Slug">
                                <Form.Control placeholder="Slug" readOnly disabled type="text" name='project_size' value={projectBasicData.slug || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                            
                            {/* <a href="javascript:;" onClick={() => toggleDetails(projectBasicData.id, projectBasicData.address.id, projectBasicData.developer.id)} data-project-id={projectBasicData.id} >
                                {buttonIcon ? <Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /> : <Icon icon='fe:edit' style={{ fontSize: '24px', color: '#192951' }} />}
                            </a> */}
                            
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <a href="javascript:;" className='theme-btn btn btn btn-primary btn-small' onClick={() => generateSlug(projectBasicData.id, projectBasicData.name)} data-project-id={projectBasicData.id} >
                           Generate Slug</a>                            
                        </div>
                    </Form.Group>
                </div>
                
                <div className='col-12'>
                <hr></hr>
                </div>
                
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Completion Time">
                                <Form.Control placeholder="Completion Time" type="date" name='completion_timestamp' value={formatDate(projectBasicData.completion_timestamp) || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Possession Date">
                                <Form.Control placeholder="Possession Date" type="date" name='possession_date_readable' value={formatDate(projectBasicData.possession_date_readable) || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
            </div>
            <div className='col-12'>
                <hr></hr>
            </div>
            <div className='text-end'>
                {/* <Button variant="secondary" onClick={handleCloseForm} className="theme-btn secondary me-2">Cancel</Button> */}
                <SubmitButton variant="success" type="submit" className={buttonClassLoader} label="Update Basic Details" />
            </div>
        </form>               
    )
}
export default ProjectEditBasicDetails;