import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import CouponButtons from '../Layouts/CouponButtons';
import CouponTag from '../Layouts/CouponTag';
import { executeLaravelFrontAPI } from './../../Admin/Utils';
import { Helmet } from 'react-helmet-async';
import { Button, Form } from 'react-bootstrap';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import { parse } from 'uuid';

const CreateJUser = () => {
    const { name, phone, slug, selldoleadid } = useParams();
    const { setDownloadCouponBtn, couponData, setCouponData, downloadCouponBtn, setIrfsProject } = useContext(SearchVisibilityContext);
    const [isOverlayVisible, setOverlayVisible] = useState(true); // Control the overlay visibility

    const handleClose = () => {
        setOverlayVisible(false); // Hide overlay on close
    };

    const [formData, setFormData] = useState({
        name: name || '',
        phone: phone || '',
        slug: slug || '',
        selldoleadid: selldoleadid || ''
    });
    const [showCoupon, setShowCoupon] = useState(false);
    const [parsedUserData, setParsedUserData] = useState(null);
    const [project, setProject] = useState(null);

    useEffect(() => {
        setDownloadCouponBtn(true)
    }, [project, showCoupon, parsedUserData]);


    const fetchUserData = async () => {
        const result = await executeLaravelFrontAPI(`admin/usercreate/${formData.name}/${formData.phone}/${formData.slug}/${formData.selldoleadid}`, '', 'GET');
        if (!result.couponcode) {
            console.error("No data found for user.");
            return;
        }
        result.couponcode.user_name = formData.name;
        // result.couponcode.project_name = formData.project.name;
        setParsedUserData(result.couponcode);
        setProject(result.project);

    };

    const handleChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetchUserData();

        // console.log(project)
        setShowCoupon(true);
    };

    return (
        <>
            <div className="main mt-5 pt-5 pb-5">
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className='container mt-5'>
                    <Form onSubmit={handleSubmit} className="mt-5">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" name="name" value={formData.name} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formBasicPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text" placeholder="Enter phone" name="phone" value={formData.phone} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formBasicSlug">
                            <Form.Label>Slug</Form.Label>
                            <Form.Control type="text" placeholder="Enter slug" name="slug" value={formData.slug} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formBasicSellDoLeadId">
                            <Form.Label>SellDo Lead ID</Form.Label>
                            <Form.Control type="text" placeholder="Enter SellDo Lead ID" name="selldoleadid" value={formData.selldoleadid} onChange={handleChange} />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Fetch User Data
                        </Button>
                    </Form>

                    <h3>Coupon Code : {parsedUserData?.coupon_code}</h3>
                    {/* <CouponButtons
                    project={project}
                    parsedUserData={parsedUserData}
                    buttonText="Download Coupon"
                /> */}
                </div>

                {/* {showCoupon && ( */}

                {/* )} */}


            </div>
            {/* {console.log('formdata', formData)} */}
            {/* {console.log('parsedUserData', parsedUserData)} */}
            {


                // parsedUserData && (
                <div className="overlayCreateUser" id='overlayCreateUser' style={{ display: isOverlayVisible && parsedUserData ? 'block' : 'none' }}>
                    <div className="overlay-content">
                    <span className="close position-absolute end-0 text-end text-black col-1 z-index10" onClick={() => { handleClose(); }}>&times;</span>
                        {
                            parsedUserData && (
                                <CouponTag
                                    couponCode={parsedUserData.coupon_code}
                                    offerName={parsedUserData.offer.name}
                                    projectName={parsedUserData.project.name}
                                    userName={parsedUserData.user.name}
                                    userPhone={parsedUserData?.user?.phone}
                                />
                            )
                        }
                    </div>
                </div>
                // )
            }
        </>
    );
};

export default CreateJUser;
