import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FloatingLabel } from 'react-bootstrap';
import { appAbsPath, executeLaravelFrontAPI, executeLaravelAPI, showToastMessage, createGa4Event, userToken } from './../../Admin/Utils';
import { CompletePurchase } from '../../Admin/GoogleAnalytics';
import { SearchVisibilityContext } from './SearchVisibilityContext';
import CouponTag from './CouponTag';
import Cookies from 'js-cookie';
import html2canvas from 'html2canvas';
import { set } from 'mongoose';
import IRFSLogo from '../../../Assets/img/irfs-logo.png';
import IRFSBack from '../../../Assets/img/back-build.png';
import bwLogo from '../../../Assets/img/beyondwalls-logoirfs.svg';
import buildirfs from '../../../Assets/img/back-build.png';
import irfsUnit from '../../../Assets/img/UNIT.svg';
import irfsTypo from '../../../Assets/img/type.svg';
import cStar from '../../../Assets/img/cstar.svg';
import IrfsLogo from '../../../Assets/img/irfscuponlogo.png';
import IrfsLogobg from '../../../Assets/img/irfslogobg.png';
import Celleft from '../../../Assets/img/celleft.png';
import Celright from '../../../Assets/img/celright.png';
import Sirfs from '../../../Assets/img/sharmanirfs.png';
import { getCookie } from './Header';

const LoginPopup = ({ onLogin, handleRegisterUserFormHideClick }) => {
    const location = useLocation();
    // const navigate = useNavigate();
    // const inputRef = useRef(null);
    const isLandingPage = location.pathname === '/';
    const [loginPhoneOTPValue, setLoginPhoneOTP] = useState("");

    const [loginFormData, setLoginFormData] = useState({ phone: '', loginPhoneOTP: '', name: '' });
    const [errors, setErrorss] = useState({ loginPhone: '', loginPhoneOtp: '', name: '' });
    const [message, setErrors] = useState({});
    const [otpform, setOTP] = useState(false);
    const [showNameField, setShowNameField] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [activeStep, setActiveStep] = useState(1); // Step state
    const [phoneNumber, setPhoneNumber] = useState('');
    const [thankYou, setThankYou] = useState(false); // Thank You step state
    const [showCouponStep, setShowCouponStep] = useState(false); // Coupon step state

    const [countdown, setCountdown] = useState(45); // Countdown timer for resend OTP button
    const [canResendOTP, setCanResendOTP] = useState(false); // State to track if resend OTP button can be clicked
    const { downloadCouponBtn, couponData, setDownloadCouponBtn } = useContext(SearchVisibilityContext);

    const [coupon, setCoupon] = useState(false);
    const [couponDetails, setCouponDetails] = useState({});
    const [loggedUserData, setUserData] = useState({});
    const [userTokenCoupon, setUserTokenCoupon] = useState(userToken);


    // Get the current date
    // const currentDate = new Date();

    // // Add 3 months to the current date
    // const expiryDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3));

    // // Format the date (e.g., 30th Oct 2024)
    // const formattedExpiryDate = expiryDate.toLocaleDateString('en-GB', {
    //     day: 'numeric',
    //     month: 'short',
    //     year: 'numeric'
    // });

    useEffect(() => {
        // console.log("loggedUserData ",  downloadCouponBtn ) 
        if (downloadCouponBtn && userToken !== null && userToken !== undefined) {
            handleRedeemCoupon();
            setActiveStep(3)
            setShowCouponStep(true)
            setThankYou(true)
            setCoupon(true)
            setOTP(true)

        }
    }, [downloadCouponBtn])

    useEffect(() => {
        let timer;
        if (otpform) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        clearInterval(timer);
                        setCanResendOTP(true);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [otpform, countdown]);

    const handleResendOTP = async () => {
        try {
            setOTP(true)
            setCanResendOTP(false);
            setCountdown(45);
            const newErrors = { ...errors };
            if (showNameField && !loginFormData.name) {
                newErrors.name = 'Name is required';
                setErrorss(newErrors);
                return;
            }
            if (!loginFormData.phone) {
                newErrors.loginPhone = 'Phone Number is required';
                setErrorss(newErrors);
                return;
            }

            // loginFormData.srd = localStorage.getItem('srd') ?? '';
            loginFormData.srd = getCookie('srd') ?? localStorage.getItem('srd') ?? '';
            const result = await executeLaravelFrontAPI('sendotp', loginFormData, 'POST');
            if (result.status === 200) {
                showToastMessage('OTP resent on your phone', 'top-right', 'success');
            } else {
                showToastMessage('Failed to resend OTP', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error resending OTP:', error);
        }
    };

    const handleShowLogin = () => setShowRegister(false);
    const handleShowSignup = () => setShowRegister(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            if (value.length === 0 || (value.length > 0 && /^[6-9]/.test(value))) {
                setPhoneNumber(value);
                setErrorss({ ...errors, loginPhone: '' });
            } else {
                setErrorss({ ...errors, loginPhone: 'Phone number must start with a digit between 6 and 9' });
            }
        } else if (name === 'name') {
            if (/^[A-Za-z\s]+$/.test(value) && value.length > 3) {
                setErrorss({ ...errors, name: '' });
            } else {
                setErrorss({ ...errors, name: 'Name must be more than 3 alphabets and contain only letters' });
            }
        }
        setLoginFormData({ ...loginFormData, [name]: value });
    };

    const handleCheckPhoneNumber = async () => {
        if (loginFormData.phone.length === 10) {
            try {
                const phoneData = { phone: loginFormData.phone };
                const result = await executeLaravelAPI('check-user', phoneData, 'POST');
                if (result.status === 200) {
                    setShowNameField(false);
                    setActiveStep(3); // Proceed to OTP step if user exists
                    handleLoginClick();
                } else {
                    setShowNameField(true);
                    setActiveStep(2); // Show name field step
                }
            } catch (error) {
                console.error('Error checking user:', error);
                setShowNameField(true);
                setActiveStep(2); // Show name field step
            }
        } else {
            setErrorss({ ...errors, loginPhone: 'Invalid phone number format' });
        }
    };

    const handleLoginClick = async () => {
        try {
            const newErrors = { ...errors };
            if (showNameField && !loginFormData.name) {
                newErrors.name = 'Name is required';
                setErrorss(newErrors);
                return;
            }
            if (!loginFormData.phone) {
                newErrors.loginPhone = 'Phone Number is required';
                setErrorss(newErrors);
                return;
            }

            // loginFormData.srd = localStorage.getItem('srd') ?? '';
            loginFormData.srd = getCookie('srd') ?? localStorage.getItem('srd') ?? '';

            const logingdata = await executeLaravelFrontAPI('sendotp', loginFormData, 'POST');
            if (logingdata.status === 200) {
                showToastMessage('OTP sent on your phone', 'top-right', 'success');
                setOTP(true);
                setLoginFormData({ ...loginFormData, loginPhoneOTP: '' });
                CompletePurchase(loginFormData.phone, window.location.pathname);
                createGa4Event('selldo_form_submitted', 'Selldo Form Submitted', `${loginFormData.phone}`);
            } else {
                showToastMessage('Phone Number is invalid!', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    const handleLoginOTPClick = async () => {
        setLoginPhoneOTP("");
        try {
            const newErrors = { ...errors };
            if (!loginFormData.loginPhoneOTP) {
                newErrors.loginPhoneOtp = ['OTP is required'];
                setErrorss(newErrors);
                return;
            } else if (loginFormData.loginPhoneOTP.length !== 6) {
                newErrors.loginPhoneOtp = ['Enter 6 digit OTP.'];
                setErrorss(newErrors);
                return;
            } else {
                newErrors.loginPhoneOtp = [''];
                setErrorss(newErrors);
            }

            const otpFormData = { phone: loginFormData.phone, otp: loginFormData.loginPhoneOTP };
            const userdata = await executeLaravelAPI('login', otpFormData, 'POST');
            if (userdata.status === 200) {
                // console.log('userdata ', userdata.access_token);
                localStorage.setItem('bwToken', userdata.access_token);
                setUserTokenCoupon(userdata.access_token);
                //setCookie('bwToken', userdata.access_token);
                Cookies.set('userData', JSON.stringify(userdata.user), { expires: 2 });
                setUserData(userdata.user);
                if (userdata.user.userrole === 'superadmin' || userdata.user.userrole === 'admin') {
                    // setAdminLoggedIn(true);
                    setTimeout(() => {
                        window.location.href = `${appAbsPath}/portaladmin/dashboard`;
                    }, 3000);
                } else {
                    // setUserLoggedIn(true);


                    if (window.location.pathname.includes('useractivitydata')) {
                        setTimeout(() => {
                            window.location.href = `${appAbsPath}`;
                        }, 5000);
                    } else {
                        setTimeout(() => {
                            if (!downloadCouponBtn) {
                                window.location.href = `${appAbsPath + window.location.href}`;
                            }
                        }, 3000);
                    }
                }


                showToastMessage('You are logged in successfully', 'top-right', 'success');
                onLogin(userdata);
                if (!downloadCouponBtn) {
                    setThankYou(true);
                    setActiveStep(4); // Move to thank you step
                } else {
                    setShowCouponStep(true);
                    handleRedeemCoupon();
                }
                // Show thank you step
                // navigate(`${appAbsPath}/portaladmin/dashboard`); // Uncomment if you want to navigate to the dashboard immediately
            } else {
                showToastMessage('OTP is invalid!', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    const handleNameSubmit = async () => {
        if (!loginFormData.name) {
            setErrorss({ ...errors, name: 'Name is required' });
            return;
        }
        await handleLoginClick();
    };
    // console.log("couponData ",couponData);
    const handleRedeemCoupon = async () => {
        // /add-couponcode

        try {

            // if user logedin 
            const checkCouponRequiredData = { pid: couponData.project_id };
            // console.log(userToken)
            const result = await executeLaravelAPI(`getcouponbyid`, checkCouponRequiredData, 'GET', localStorage.getItem('bwToken'));

            if (result.status === 200 || result.is_empty === false) {
                // const couponrequiredData = { offer_id: couponData.offer_id, project_id:  couponData.project_id};
                // console.log("Coupon Details data by id", result.data);
                setCoupon(true);
                setCouponDetails(result.data);
                setActiveStep(4); // Move to thank you step
                setDownloadCouponBtn(false);
            } else {
                const couponrequiredData = { offer_id: couponData.offer_id, project_id: couponData.project_id };

                // console.log(couponrequiredData);
                const couponDetails = await executeLaravelAPI('add-couponcode', couponrequiredData, 'POST', localStorage.getItem('bwToken'));

                if (couponDetails.status === 200) {
                    showToastMessage('Coupon Redeemed', 'top-right', 'success');
                    // setThankYou(true); // Show thank you step
                    console.log("Coupon Details data", couponDetails.data);
                    setCoupon(true);
                    setCouponDetails(couponDetails.data);
                    setActiveStep(4); // Move to thank you step
                    setDownloadCouponBtn(false);
                }

            }


        }
        catch (error) {
            console.error('Error redeeming coupon:', error);
        }

    }

    const downloadCoupon = () => {
        try {
            // download coupone html as img
            console.log('download coupon');
            const couponData = document.querySelector('.coupon-wrapper');

            const canvas = document.createElement('canvas');
            canvas.width = couponData.offsetWidth;
            canvas.height = couponData.offsetHeight;
            const ctx = canvas.getContext('2d');
            const img = new Image();

            img.onload = function () {
                console.log('Image loaded successfully');
                ctx.drawImage(img, 0, 0);
                const a = document.createElement('a');
                a.href = canvas.toDataURL('image/png');
                a.download = 'coupon.png';
                a.click();
            };

            img.onerror = function (error) {
                console.error('Error loading image:', error);
            };

            try {
                const svgString = new XMLSerializer().serializeToString(couponData);
                const base64Data = btoa(svgString);
                img.src = 'data:image/svg+xml;base64,' + base64Data;
                console.log('Image source set:', img.src);
            } catch (error) {
                console.error('Error serializing SVG:', error);
            }
            console.log(img)
            setDownloadCouponBtn(false);
            setThankYou(false);

            setTimeout(() => {
                console.log('Redirecting to the current page');
                window.location.href = `${appAbsPath + window.location.href}`;

            }, 3000);


        }
        catch (error) {
        }
    }

    const handleEditPhoneClick = () => {
        setActiveStep(1);
        setOTP(false);
        setShowNameField(false);
        setThankYou(false); // Reset thank you state
        // Reset only other relevant states
        setErrorss({ loginPhone: '', loginPhoneOtp: '', name: '' });
    };

    const downloadCouponpdf = () => {
        const input = document.querySelector('.coupon-wrapper');
        const excludeDiv = input.querySelector('.exclude-this');

        if (!input) {
            console.error('Coupon box not found');
            return;
        }

        if (excludeDiv) {
            excludeDiv.style.display = 'none';
        }

        html2canvas(input, { useCORS: false, scale: 2, logging: false, allowTaint: false })
            .then((canvas) => {
                // Restore the excluded div's visibility
                if (excludeDiv) {
                    excludeDiv.style.display = '';
                }

                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = 'coupon.png';
                link.click();
                setDownloadCouponBtn(false);
                setThankYou(false);

                if (!userToken) {
                    setTimeout(() => {
                        window.location.href = `${appAbsPath + window.location.href}`;
                    }, 3000);
                }
            })
            .catch((error) => {
                console.error("Could not download the coupon:", error);
                // Restore the excluded div's visibility in case of error
                if (excludeDiv) {
                    excludeDiv.style.display = '';
                }
            });
    };

    const refreshOnClick = () => {
        if (!userToken && !downloadCouponBtn) {
            setTimeout(() => {
                window.location.href = `${appAbsPath + window.location.href}`;
            }, 1000);
        }
    }



    return (
        <div className={`overlay ${window.location.pathname.includes('irfs') ? 'irfsoverlay' : ''}`} id="overlay">
            <div className="overlay-content">
                <div className="row gap-2 gap-md-0 bg-white loginpopupcontent">
                    <span className="close position-absolute end-0 text-end text-black col-1 z-index10" onClick={() => { handleRegisterUserFormHideClick(); refreshOnClick(); }}>&times;</span>
                    <div className="col-12 col-md-12 order-first order-md-1 popcol">
                        <div className="flex-column justify-content-evenly h-100 col-12 col-md-12 m-auto gap-4 gap-md-0" id="email-div">
                            {!thankYou && !showCouponStep && (
                                <div className='steps-anim'>
                                    <span className={`first ${activeStep >= 1 ? 'active' : ''}`}>1</span>
                                    <span className={`second ${activeStep >= 2 ? 'active' : ''}`}>2</span>
                                    <span className={`third ${activeStep >= 3 ? 'active' : ''}`}>3</span>
                                    <span className={`fourth ${activeStep >= 4 ? 'active' : ''}`}>4</span>
                                </div>
                            )}
                            <form>

                                {!otpform ? (
                                    <>
                                        {activeStep === 1 && (
                                            <div className='loginfirst'>
                                                <Form.Group className="row mt-4 mb-3">
                                                    <div className="col-sm-12 position-relative">
                                                        <div>
                                                            <h3 className="m-0 mt-4 mt-md-0 fw-bold log-title" id="login_title">BeyondWalls</h3>
                                                            <h3 className='log-subtitle'>House-hunting Made Easy</h3>
                                                            <p className='log-desc'>Exclusive Listings  I  Early Access to projects</p>
                                                        </div>
                                                        <FloatingLabel controlId="floatingEmail11" label="Phone Number">
                                                            <Form.Control
                                                                className='logininput'
                                                                placeholder="Phone Number"
                                                                type="tel"
                                                                name='phone'
                                                                pattern="[6-9]{1}[0-9]{9}"
                                                                maxLength="10"
                                                                minLength="10"
                                                                value={loginFormData.phone} // Use loginFormData.phone here
                                                                onChange={handleChange}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                    if (event.target.value.length === 0 && /[1-5]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </FloatingLabel>
                                                        {errors && errors.loginPhone && <div className="text-danger error-msg">{errors.loginPhone}</div>}
                                                    </div>
                                                </Form.Group>
                                                <div className="mb-4 nextbtn">
                                                    <button type="button" onClick={handleCheckPhoneNumber} className="btn sc register-btn px-4 fs-6">
                                                        <span className="signin text-uppercase">Next</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {showNameField && activeStep === 2 && (
                                            <div className='loginsecond'>
                                                <Form.Group className="row mt-4 mb-3">
                                                    <div>
                                                        <h3 className="m-0 mt-4 mt-md-0 fw-bold log-title" id="login_title">Find Your Dream Home</h3>
                                                        <h3 className='log-subtitle'>Choose from thousands of options </h3>
                                                        <p className='log-desc'>Wishlist Properties  I  Download Brochures</p>
                                                    </div>
                                                    <div className="col-sm-12 position-relative">
                                                        <FloatingLabel controlId="floatingName1" label="Name">
                                                            <Form.Control className='logininput' placeholder="Name" name='name' onChange={handleChange} value={loginFormData.name} required />
                                                        </FloatingLabel>
                                                        {errors && errors.name && <div className="text-danger error-msg">{errors.name}</div>}
                                                    </div>
                                                </Form.Group>
                                                <div className="mb-4 mt-3 nextbtn">
                                                    <button type="button" onClick={handleNameSubmit} className="btn sc register-btn px-4 fs-6">
                                                        <span className="signin text-uppercase">Send OTP</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (

                                    <>
                                        {
                                            thankYou || showCouponStep ? (
                                                coupon ? (
                                                    <CouponTag
                                                        // couponDetails={couponDetails}
                                                        couponCode={couponDetails.coupon_code}
                                                        offerName={couponDetails.offer_name}
                                                        projectName={couponDetails.project_name}
                                                        userName={couponDetails.user_name}
                                                        userPhone ={couponDetails?.user?.phone}
                                                        // downloadCouponpdf={downloadCouponpdf}
                                                    />
                                                ) : (

                                                    <div className="thank-you-step loginthank">
                                                        <h3 className="m-0 enter-otp txtcenter">Thank You :)</h3>
                                                        <svg className='tickthank' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                            <circle class="path circle" fill="none" stroke="#42B57E" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                                            <polyline class="path check" fill="none" stroke="#42B57E" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                                        </svg>
                                                    </div>
                                                )
                                            ) : (
                                                <>
                                                    <div className='loginthird'>
                                                        <h3 className="m-0 enter-otp txtcenter">Enter OTP</h3>
                                                        <p className="m-0 mt-2 mb-4 txtcenter">
                                                            Enter OTP Sent to Your <br />
                                                            Mobile No.{' '}
                                                            <a className='text-primary editphone' onClick={handleEditPhoneClick}>
                                                                <span>+91 {loginFormData.phone}</span><span><svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="m18.988 2.012l3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287l-3-3L8 13z" /><path fill="currentColor" d="M19 19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2z" /></svg></span>
                                                            </a>
                                                        </p>
                                                        <div id="register-error"></div>
                                                        <p className='log-desc'>AI based Property Scores  I  No Spam Calls</p>
                                                    </div>
                                                    <div className='loginthird1'>
                                                        <Form.Group className="row mb-4">
                                                            <div className="col-sm-12 position-relative">
                                                                <FloatingLabel controlId="floatingphoneOTP" label="Enter received OTP">
                                                                    <Form.Control
                                                                        className='logininput'
                                                                        placeholder="Enter received OTP"
                                                                        type="number"
                                                                        name='loginPhoneOTP'
                                                                        pattern="[0-9]{6}"
                                                                        maxLength="6"
                                                                        minLength="6"
                                                                        onChange={handleChange}
                                                                        value={loginFormData.loginPhoneOTP}
                                                                        autoComplete="off"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        required
                                                                    />
                                                                </FloatingLabel>
                                                                {errors && errors.loginPhoneOtp && <div className="text-danger error-msg">{errors.loginPhoneOtp}</div>}
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="mt-4 otpbtn">
                                                        <button type="button" onClick={handleLoginOTPClick} className="btn sc register-btn px-4 fs-6 otpsubmit">
                                                            <span className="signin text-uppercase">Continue</span>
                                                        </button>
                                                        <div className="resend-otp mt-3">
                                                            <button
                                                                type="button"
                                                                onClick={handleResendOTP}
                                                                className="btn btn-link px-0"
                                                                disabled={!canResendOTP}
                                                            >
                                                                {canResendOTP ? 'Resend OTP' : `Resend OTP in ${countdown}s`}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }

                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPopup;
