import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon } from 'react-share';
import { ReactComponent as CustomTwitterIcon } from '../../../Assets/img/x-twitter.svg';


const ShareButtonWithPopup = ({projecturl}) => {
    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = (e) => {
        e.preventDefault();
        setShowPopup(!showPopup);
    };

    return (
        <div style={{ position: 'relative' }}>
            <Link className="shareBtn" onClick={togglePopup}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24">
                    <path fill="white" d="M16.61 21q-.994 0-1.687-.695q-.692-.696-.692-1.69q0-.15.132-.757l-7.197-4.273q-.324.374-.793.587t-1.007.213q-.986 0-1.676-.702T3 12t.69-1.683t1.676-.702q.537 0 1.007.213t.793.588l7.198-4.255q-.07-.194-.101-.385q-.032-.192-.032-.392q0-.993.697-1.689Q15.625 3 16.62 3t1.688.697T19 5.389t-.695 1.688t-1.69.692q-.542 0-1-.222t-.78-.597l-7.199 4.273q.07.194.101.386q.032.191.032.391t-.032.391t-.1.386l7.198 4.273q.323-.375.78-.597q.458-.222 1-.222q.994 0 1.69.696q.695.698.695 1.693t-.697 1.688t-1.692.692m.004-1q.589 0 .987-.398t.398-.986t-.398-.987t-.986-.398t-.987.398t-.398.986t.398.987t.987.398m-11.25-6.616q.596 0 1-.398q.403-.398.403-.986t-.403-.986t-1-.398q-.581 0-.973.398T4 12t.393.987t.973.397m11.25-6.615q.588 0 .986-.398T18 5.384t-.398-.986T16.616 4t-.987.398t-.398.987t.398.986t.987.398m0-1.385" />
                </svg>
            </Link>

            <div className={`popup ${showPopup ? 'show' : ''}`}>
                <div className="popupShareBtn-content">
                    <div className="social-icons">
                        <FacebookShareButton url={projecturl}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton url={projecturl}>
                            <CustomTwitterIcon width={32} height={32} />
                        </TwitterShareButton>
                        <LinkedinShareButton url={projecturl}>
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        <WhatsappShareButton url={projecturl}>
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </div>
                    {/* <button className="close-btn" onClick={togglePopup}>&times;</button> */}
                </div>
            </div>
        </div>
    );
};

export default ShareButtonWithPopup;
