import React, { useEffect, useState, useRef, useContext } from 'react';
import Cookies from 'js-cookie';
import { Dropdown } from 'react-bootstrap';
import { appAbsPath, executeLaravelFrontAPI, executeLaravelAPI, userToken, showToastMessage, parsedUserData, createGa4Event, isScreenWidthGreaterThan992, isScreenWidthLessThan992 } from './../../Admin/Utils';
import logo from '../../../images/logo.svg';
import smalllogo from '../../../Assets/img/small-logo.svg';
import $ from 'jquery';
import BottomNavBar from '../Pages/IRFS/BottomNavBar';
// import regImg from '../../../images/building-img.svg';

import { useLocation, Link, useNavigate } from 'react-router-dom';
// import { Button, Form, FloatingLabel } from 'react-bootstrap';
// import { Icon } from '@iconify/react';
import { SearchVisibilityContext } from './SearchVisibilityContext';

// import Loader from '../../Loader';
import SearchInput from "./SearchInput";
// import Loader from '../../Loader';
// import SearchResults from "./SearchResults";

// import {setProjectSearchLocation,setProjectSearchDeveloper, setProjectSearchConfiguration} from './../../Admin/UserActivatyTracker';
import LoginPopup from './LoginPopup';
import SearchPopup from './SearchPopup';
import { setCookie } from '../../Admin/UserActivatyTracker';
// import { timers } from 'jquery';
const isUserLoggedIn = () => {
    // Replace this with your actual login check logic
    return sessionStorage.getItem('isLoggedIn') === 'true';
};
export const getCookie = (name) => {
    return Cookies.get(name);
};


const Header = ({ allParams, onCityChange, onLogin }) => {
    const location = useLocation();
    const navigate = useNavigate();
    // const inputRef = useRef(null);
    // const isLandingPage = location.pathname === '/';
    const currentUrl = location.pathname;
    // console.log(loggeduserdetails.name);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const suggestionContainerRef = useRef(null);

    const pageDetails = { 'pagetitle': 'Home', 'pageslug': '/' };
    const [showResult, showSearchResult] = useState(false);
    // const [showDefaultResult, showDefaultSearchResult] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState([]);
    // const [showOnLocationList, clickOnLocationList] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    // const [currCity, setCurrCity] = useState(sessionStorage.getItem('city') || 'pune');
    let currentUrlHome = currentUrl;

    const { isVisible, setIsVisible, searchOptions, setSearchOptions, irfsProject, setIrfsProject } = useContext(SearchVisibilityContext);

    // Search filter
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchLocationResults, setLocationSearchResults] = useState([]);
    // const [searchDevResults, setDevSearchResults] = useState([]);
    // const [searchConfigurationsResults, setConfigurationSearchResults] = useState([]);
    const uniqueLocalitiesRegion = new Set();
    const uniqueLocalitiesArea = new Set();
    const [isFocused, setIsFocused] = useState(false);
    // const uniqueLocalities = new Set();uniqueLocalitiesRegion

    // Out side click hide div
    // const [loading, setLoading] = useState(false);
    const searchInputRef = useRef(null);
    const [visitedIrfsCookie, setVisitedIrfsCookie] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    // const [loginFormData, setLoginFormData] = useState({ phone: '', loginPhoneOTP: '' });
    // const [errors, setErrorss] = useState({ loginPhone: '', loginPhoneOtp: '' });
    // const [message, setErrors] = useState({});
    // const [otpform, setOTP] = useState(false);
    // const [otpformSignup, setOTPSignup] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        // password: '',
        // confirmPassword: '',
        userrole: 'user', // Set userrole to 'user'
        agreetnc: false
    });

    useEffect(() => {
        if(!window.location.pathname.includes('projects')) {
          window.scrollTo(0, 0);
        }
      }, [window.location.pathname]);

    // hide react-select dorpdown if isVisible is false
    useEffect(() => {
        if (!isVisible) {
            $('.select2-container--open .select2-dropdown').css('display', 'none').remove();
        }
    }, [isVisible]);
    // const [loginPhoneOTPValue, setLoginPhoneOTP] = useState("");


    useEffect(() => {
        if (location.pathname !== '/') {
            setIsVisible(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        //progressBar(setLoading, setProgress);
        if (userToken !== null) {
            const checkAuthentication = async () => {
                try {
                    // Make a request to your Laravel backend to check if the user is authenticated
                    const userdata = await executeLaravelAPI('check-auth', '', 'GET', userToken);
                    if (userdata.status === 200) {
                        Cookies.set('userData', JSON.stringify(userdata.user), { expires: 2 });
                    }
                    else {
                        localStorage.removeItem('bwToken');
                        Cookies.remove('userData');
                        window.location.href = `${appAbsPath}/`;
                    }

                } catch (error) {
                    console.error('Error checking authentication:', error);
                }
            };
            checkAuthentication();
        }
    }, []);



    if (currentUrl === '/') {
        currentUrlHome = '/projects';
    }
    // console.log("user data name:---------------------", userdetails.name);
    // const paramsData = {};
    const getAllParams = (location) => {
        const searchParams = new URLSearchParams(location.search);
        const allParams = {};
        for (const [key, value] of searchParams.entries()) {
            allParams[key] = value;
        }

        if (allParams.srd) {
            localStorage.setItem("srd", allParams.srd);
            setCookie('srd', allParams.srd, 1);
        }
        return allParams;
    }
    useEffect(() => {
        document.title = pageDetails.pagetitle;
        const params = getAllParams(location);
        if (params.locality_name != "" && params.locality_name != null || params.locality_name != undefined) {
            setSelectedLocations(params.locality_name.split(','));
            setLocality(params.locality_name);
        } else {
            setSelectedLocations([]);
        }
    }, []);


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const localityNames = searchParams.get('locality_name');
        if (localityNames) {
            setSelectedLocations(localityNames.split(','));
        } else {
            setSelectedLocations([]);
        }
    }, [location.search]);




    const handleOutsideClick = (e) => {
        if (
            suggestionContainerRef.current &&
            !suggestionContainerRef.current.contains(e.target) &&
            searchInputRef.current &&
            !searchInputRef.current.contains(e.target)
        ) {
            showSearchResult(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        // const storedCity = sessionStorage.getItem('city');
        // if (storedCity) {
        // setCurrCity(storedCity);
        // }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []); // Run the effect only once during component mount
    //End Out side click hide div

    //City change
    // const handleCityChange = (e) => {
    //     sessionStorage.removeItem('city');
    //     const selectedCity = e.target.value;
    //     setCurrCity(selectedCity);
    //     // onCityChange(e.target.value);
    //     sessionStorage.setItem('city', selectedCity);
    //     // const newUrl = `${appAbsPath}/projects/?city=${e.target.value}`;
    //     // navigate(newUrl, { replace: true });
    //     window.location.href=`${appAbsPath}/projects/?city=${e.target.value}`;
    // };


    // console.log(window.location.href);
    const addLocality = (locality) => {

        if (!selectedLocations.includes(locality)) {

            const updatedLocations = [...selectedLocations, locality];
            setSelectedLocations(updatedLocations);
            // console.log(selectedLocations)
            // Prepare the updated URL
            let paramsURL = new URLSearchParams(location.search);
            paramsURL.set('searchtype', 'locality_name');
            paramsURL.set('locality_name', updatedLocations.join(',').toLowerCase());
            location.search = paramsURL.toString();
            console.log('New location :- ', location.search);
            const newUrl = `${currentUrlHome}?${location.search}`;
            // const newUrl = `${currentUrlHome}?searchtype=locality_name&locality_name=${updatedLocations.join(',').toLowerCase()}`;
            // Use navigate to change the URL, reflecting the new state
            // console.log(newUrl)
            // console.log(newUrl);
            navigate(newUrl, { replace: true });
        }
        showSearchResult(false);
        setSearchQuery('');
    };
    const updateUrlWithLocalities = (updatedLocalities) => {
        let paramsURL = new URLSearchParams(location.search);
        paramsURL.set('searchtype', 'locality_name');
        paramsURL.set('locality_name', updatedLocalities.join(',').toLowerCase());
        location.search = paramsURL.toString();
        console.log('New location :- ', location.search);
        const newUrl = `${currentUrlHome}?${location.search}`;
        // const newUrl = `${currentUrlHome}?searchtype=locality_name&locality_name=${updatedLocalities.join(',').toLowerCase()}`;
        navigate(newUrl, { replace: true });
    };
    // console.log(appAbsPath);
    const removeLocation = (locationToRemove) => {
        const newLocations = selectedLocations.filter((location) => location !== locationToRemove);
        setSelectedLocations(newLocations);
        updateUrlWithLocalities(newLocations);
        showSearchResult(false);
        setSearchQuery('');
    };
    const hideDropdownMenu = () => {
        showSearchResult(false);
        setSearchQuery('');
    }
    //Click on locality name
    const setLocality = async (locality) => {
        try {
            if (selectedLocations != "" && locality != "" && locality != null || locality != undefined) {
                if (!selectedLocations.includes(locality)) {
                    // Update the array with the new location
                    const updatedLocations = [...selectedLocations, locality];
                    // setSelectedLocations([...selectedLocations, locality]);
                    const params = {
                        locality_name: updatedLocations,
                        searchtype: 'locality_name',
                        page: 1,
                        per_page: 5,
                    };
                    // console.log(locality);
                    const resultData = await executeLaravelFrontAPI('projects', params, 'GET');
                    setSearchResults(resultData.data.data);
                }
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
        // showSearchResult(false);
    };
    // Create a mapping of localities to projects
    const localityProjectsMap = {};
    searchLocationResults.forEach(project => {
        const locality = project.locality_name;
        if (!localityProjectsMap[locality]) {
            localityProjectsMap[locality] = [];
        }
        localityProjectsMap[locality].push(project);
    });
    searchResults.forEach(project => {
        uniqueLocalitiesRegion.add(project.region); // Store lowercase to ensure case-insensitivity
        uniqueLocalitiesArea.add(project.locality_name); // Store lowercase to ensure case-insensitivity
    });

    const handleLogout = async () => {
        //alert('logout');
        try {
            localStorage.removeItem('bwToken'); localStorage.clear();
            Cookies.remove('userData');
            //deleteCookie('bwToken')
            const userdata = await executeLaravelAPI('logout', '', 'POST', userToken);
            if (userdata.status === 200) {
                const VisitedIrfs = getCookie('VisitedIrfs');
                if (VisitedIrfs === 'true') {
                    Cookies.remove('VisitedIrfs');
                    setVisitedIrfsCookie(false);
                    window.location.href = `${appAbsPath}/irfs`;
                }
                else {
                    Cookies.remove('VisitedIrfs');
                    setVisitedIrfsCookie(false);
                    window.location.href = `${appAbsPath}/`;
                }
            } else {
                showToastMessage('Something Went Wrong!', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

        //irfs path check
        const [isIrfsPath, setIsIrfsPath] = useState(false);
        const [isProfilePage, setIsProfilePage] = useState(false);
        // const [irfsProject, setIrfsProject] = useState(false);
        // const location = useLocation();
        
        // const location = useLocation();
    
        useEffect(() => {
            const checkIrfsPath = () => {
                const pathname = location.pathname.split('?')[0]; // Ignoring any query parameters
                const firstSegment = pathname.split('/')[1]; // Get the first segment after the domain
                if (firstSegment === 'irfs' || irfsProject === true) {
                    setCookie('VisitedIrfs', 'true', 1);
                    setVisitedIrfsCookie(true);
                    setIsIrfsPath(true);
                    setIrfsProject(true); // Ensure irfsProject is set to true
                }
            };
        
            const checkProfilePage = () => {
                const pathname = location.pathname.split('?')[0]; // Ignoring any query parameters
                const firstSegment = pathname.split('/')[1]; // Get the first segment after the domain
                const VisitedIrfs = getCookie('VisitedIrfs');
                if (firstSegment === 'profile' && VisitedIrfs === 'true') {
                    setIsProfilePage(true);
                } else {
                    setIsProfilePage(false);
                }
            };
        
            checkIrfsPath(); // Initial check for /irfs path
            checkProfilePage(); // Initial check for profile page
        
            // Optional: if you want to react to route changes
            // window.addEventListener('popstate', checkIrfsPath);
            // return () => window.removeEventListener('popstate', checkIrfsPath);
        
        }, [location]);

    //Register Form
    const handleRegisterUserClick = (formRequest) => {
        document.getElementById('overlay').style.display = 'block';
        sessionStorage.setItem('popupShown', 'true');
        const pathname = location.pathname.split('?')[0]; // Ignoring any query parameters
        const firstSegment = pathname.split('/')[1]; // Get the first segment after the domain
        if (firstSegment === 'irfs' || irfsProject === true) {
            setCookie('VisitedIrfs', 'true', 1);
            setVisitedIrfsCookie(true);
            setIsIrfsPath(true);
            setIrfsProject(true);
        }
    }
    const handleRegisterUserFormHideClick = () => {
        document.getElementById('overlay').style.display = 'none';
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    // const handleUserAddSubmit = async (e) => {
    //     // e.preventDefault();

    //     try {
    //         /// Basic form validation
    //         const { name, email, phone, agreetnc } = formData;
    //         const messages = [];

    //         formData.srd = localStorage.getItem('srd') ?? '';

    //         if (!name.trim()) {
    //             messages.name = ['Name is required'];
    //         } else if (!/^[a-z A-Z\s]+$/.test(name)) {
    //             messages.name = ['Name should only contain alphabetic characters'];
    //         }

    //         if (!phone.trim()) {
    //             messages.phoneNumber = ['Phone number is required'];
    //         } else if (!/^[6-9]\d{9}$/.test(phone)) {
    //             messages.phoneNumber = ['Invalid phone number format'];
    //         }

    //         if (!email.trim()) {
    //             messages.email = ['Email is required'];
    //         } else if (!/^\S+@\S+\.\S+$/.test(email)) {
    //             messages.email = ['Invalid email address'];
    //         }

    //         // if (!password.trim()) {
    //         //     messages.password = ['Password is required'];
    //         // } else if (password.length < 6) {
    //         //     messages.password = ['Password must be at least 6 characters long'];
    //         // }

    //         // if (password !== confirmPassword) {
    //         //     messages.confirmPassword = ['Passwords do not match'];
    //         // }

    //         if (!agreetnc) {
    //             messages.agreetnc = 'You must agree to the terms and conditions';
    //         }

    //         if (Object.keys(messages).length > 0) {
    //             setErrors(messages);
    //             return;
    //         }
    //         console.log(formData);
    //         // Assuming userToken is defined elsewhere
    //         let result = await executeLaravelAPI('register', formData, 'POST', userToken);
    //         // console.table(result.message);
    //         if (result.status === 409 || result.status === 400) {
    //             setErrors(result.message);
    //             console.log(result.message.email);
    //         } else if (result.status === 200) {
    //             showToastMessage('OTP sent on your phone', 'top-right', 'success');
    //             setOTPSignup(true);
    //         } else {
    //             showToastMessage('Phone Number is invalid!', 'top-right', 'error');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         showToastMessage('Failed to add user', 'top-right', 'error');
    //     }
    // }
    // const handleSignupOTPClick = async () => {
    //     try {
    //         const newErrors = { ...message };
    //         if (!formData.signupPhoneOTP) {
    //             newErrors.signupPhoneOtp = 'OTP is required';
    //             setErrors(newErrors);
    //             return;
    //         }
    //         console.log("Formdata",formData);

    //         const otpFormData = { phone: formData.phone, otp: formData.signupPhoneOTP };
    //         const userdata = await executeLaravelAPI('userverified', otpFormData, 'POST');
    //         if (userdata.status === 200) {
    //             showToastMessage('You are registered in successfully', 'top-right', 'success');
    //             onLogin(userdata);
    //             navigate(`${appAbsPath}/projects`);
    //         } else {
    //             showToastMessage('Register details are invalid!', 'top-right', 'error');
    //         }
    //     } catch (error) {
    //         console.error('Error logging in:', error);
    //     }
    // };
    window.onload = function () {
        var loginButton = document.getElementById('login_button');
        if (loginButton) {
            loginButton.addEventListener('click', function () {
                var loginTitle = document.getElementById('login_title');
                if (loginTitle) {
                    loginTitle.textContent = 'Log in';
                }
            });
        }
    }
    const handleShowLogin = () => {
        setShowRegister(false);
    };

    // const handleShowSignup = () => {
    //     setShowRegister(true);
    // };


    // const handleLoginChange = (e) => {
    //     setLoginPhoneOTP(e.target.value);
    //     const { name, value } = e.target;
    //     const phoneNumber = /^[0-9]{10}$/.test(value) ? parseInt(value) : value;
    //     setLoginFormData((prevFormData) => ({ ...prevFormData, [name]: phoneNumber }));
    //     setErrorss((prevErrors) => ({ ...prevErrors, [name]: '' }));
    // };

    // const handleLoginClick = async () => {
    //     try {
    //         const newErrors = { ...errors };
    //         if (!loginFormData.phone) {
    //             newErrors.loginPhone = 'Phone Number is required';
    //             setErrorss(newErrors);
    //             return;
    //         }

    //         const logingdata = await executeLaravelFrontAPI('sendotp', loginFormData, 'POST');
    //         if (logingdata.status === 200) {
    //             showToastMessage('OTP sent on your phone', 'top-right', 'success');
    //             setOTP(true);
    //             setLoginFormData({ ...loginFormData, loginPhoneOTP: '' }); 
    //         } else {
    //             showToastMessage('Phone Number is invalid!', 'top-right', 'error');
    //         }
    //     } catch (error) {
    //         console.error('Error logging in:', error);
    //     }
    // };

    // const handleLoginOTPClick = async (e) => {
    //     setLoginPhoneOTP("");
    //     try {
    //         const newErrors = { ...errors };
    //         if (!loginFormData.loginPhoneOTP) {
    //             newErrors.loginPhoneOtp = ['OTP is required'];
    //             setErrorss(newErrors);
    //             return;
    //         } else if (loginFormData.loginPhoneOTP.length !== 6) {
    //             newErrors.loginPhoneOtp = ['Enter 6 digit OTP.'];
    //             setErrorss(newErrors);
    //             return;
    //         }else {
    //             newErrors.loginPhoneOtp = [''];
    //             setErrorss(newErrors);
    //         }

    //         const otpFormData = { phone: loginFormData.phone, otp: loginFormData.loginPhoneOTP };
    //         const userdata = await executeLaravelAPI('login', otpFormData, 'POST');
    //         if (userdata.status === 200) {
    //             showToastMessage('You are logged in successfully', 'top-right', 'success');
    //             onLogin(userdata);
    //             navigate(`${appAbsPath}/portaladmin/dashboard`);
    //         } else {
    //             showToastMessage('OTP is invalid!', 'top-right', 'error');
    //         }
    //     } catch (error) {
    //         console.error('Error logging in:', error);
    //     }
    // };

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const pageHeight = document.body.clientHeight;
            const scrollPercentage = (scrollPosition / pageHeight) * 100;
            // if(isLandingPage){
            setIsSticky(scrollPercentage >= 45);
            // }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    useEffect(() => {
        setSelectedIndex(0);
    }, [searchQuery]);

    // const getHighlightedClass = (index) => (index === selectedIndex ? 'highlighted' : '');

    useEffect(() => {
        const checkFormVisibility = () => {
            if (searchInputRef.current) {
                const formStyles = window.getComputedStyle(searchInputRef.current);
                const isHidden = formStyles.display === 'none' || formStyles.visibility === 'hidden';
                setIsVisible(!isHidden);
            }
        };

        checkFormVisibility();
        window.addEventListener('resize', checkFormVisibility); // Adjust as needed
        return () => window.removeEventListener('resize', checkFormVisibility); // Clean up
    }, []);

    // useEffect(() => {
    //     if (!isUserLoggedIn()) {
    //     const popupShown = sessionStorage.getItem('popupShown');

    //     if (popupShown === null) {
    //         const timer = setTimeout(() => {
    //             handleRegisterUserClick();
    //         }, 25000);

    //         return () => clearTimeout(timer);
    //     }}
    // }, []);

    useEffect(() => {
        if (!isUserLoggedIn()) {
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    const tabPopupShown = sessionStorage.getItem('tabPopupShown');

                    if (tabPopupShown === null) {
                        // handleRegisterUserClick();
                        sessionStorage.setItem('tabPopupShown', 'true');
                    }
                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
    }, []);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        // Add event listener to track window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    

    const [isWideScreen, setIsWideScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            if (isScreenWidthGreaterThan992()) {
                setIsWideScreen(true);
            } else if (isScreenWidthLessThan992()) {
                setIsWideScreen(false);
            }
        };

        // Set initial screen width check
        handleResize();

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // const handleSomeAction = () => {
    //     // Ensure you are setting irfsProject correctly
    //     if (condition) {
    //       setIrfsProject(true);
    //     } else {
    //       setIrfsProject(false);
    //     }
    //   };
    // useEffect(() => {
    //     // console.log('irfsProject state changed:', irfsProject);
    //   }, [irfsProject]);


    // show search bar on scroll rushi  

    useEffect(() => {
        const handleScroll = () => {
            // Check if the page has been scrolled by 20% of viewport height
            if (window.scrollY >= window.innerHeight * 0.2) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
    
        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <>

            <header className={`header navbar-light pt-lg-3 pb-lg-3 pt-2 pb-2 sticky ${isIrfsPath || irfsProject ? 'irfsheader' : ''}`} id="myHeader">
                <div className="custompadding">
                    <div className="align-items-center nav-flex-box d-flex justify-content-between ">
                        <a href={`${isIrfsPath || irfsProject || visitedIrfsCookie ? '/irfs' : '/'}`} rel="home" data-event-category="Header" data-event-action="Click" data-event-name="Home">
                            <div className="header-img">
                                <div className='d-none d-sm-block'>
                                    <img src={logo} alt="Beyondwalls Logo" width="240px" />
                                </div>
                                <div className='d-block d-sm-none'>
                                    {isScrolled ? (
                                            <img src={smalllogo} className='secondlogo' alt="Beyondwalls Logo" width="240px" />
                                        ) : (
                                                <img src={logo} alt="Beyondwalls Logo" width="240px" />
                                        )
                                    }
                                </div>
                            </div>
                        </a>
                        <div className="header-btns d-flex justify-content-end flex-1 align-items-center">
                            {/* {console.log('adarsh', isMobile)} */}
                            {isVisible && (
                                !isMobile ? (
                                    <form className="global-header position-relative flex-1" ref={searchInputRef}>
                                        {/* <div>desktop</div> */}
                                        <div className="d-flex search-header position-relative">
                                            <SearchInput irfs={isIrfsPath} />
                                        </div>
                                    </form>
                                ) : (
                                    isScrolled && (
                                        <div className="serachiconshow">
                                            {/* <div>mobile</div> */}
                                            <SearchPopup iconShow={true} irfs={isIrfsPath} />
                                        </div>
                                    )
                                )
                            )}
                            {/* {isVisible && (
                                !isMobile ? (
                                    <form className="global-header position-relative flex-1" ref={searchInputRef}>
                                        <div>desktop</div>
                                        <div className="d-flex search-header position-relative">
                                            <SearchInput irfs={isIrfsPath}/>
                                        </div>
                                    </form>
                                ) : (
                                    <div className="serachicon">
                                        <div>mobile</div>
                                        <SearchPopup iconShow={true} irfs={isIrfsPath}/>
                                    </div>
                                )
                            )} */}

                            {(parsedUserData === null) ? (
                                <>
                                    <div className="d-flex align-items-center btn-flex-box">
                                        {
                                            !isIrfsPath && (
                                                <div className='d-none'>
                                                <a href="https://corporate.beyondwalls.com/" target='_blank' className='btn btn-primary sc login-btn d-767-none-i overlay-button text-nowrap btn-login corporate-btn' onClick={() => [createGa4Event('Click', 'Header', 'Corporate Site')]}>
                                                    <span>Corporate</span>
                                                </a>
                                                </div>
                                            )
                                        }
                                        {
                                            (!isIrfsPath || (isIrfsPath && isWideScreen)) && !(isProfilePage && !isWideScreen) && (
                                                <div className='d-none d-sm-block'>
                                                <Link className='btn btn-primary sc login-btn d-767-none-i overlay-button text-nowrap btn-login' onClick={() => [handleRegisterUserClick(), handleShowLogin(), createGa4Event('header_login', 'HeaderLogIN', 'Log in')]}>
                                                    <span>Log in</span>
                                                </Link>
                                                </div>
                                            )
                                        }
                                    </div>
                                    </>

                            ) : (

                                parsedUserData.userrole == 'superadmin' ? (
                                    <>
                                    {
                                        !isIrfsPath && (
                                            <div className='d-none'>
                                            <a href="https://corporate.beyondwalls.com/" target='_blank' className='btn btn-primary sc login-btn d-767-none-i overlay-button text-nowrap btn-login' onClick={() => [createGa4Event('Click', 'Header', 'Corporate Site')]}>
                                                <span>Corporate</span>
                                            </a>
                                            </div>
                                        )
                                    }
                                        <ul className="navbar-nav navbar-nav-right">

                                            <li className="nav-item nav-profile">
                                                <Dropdown>
                                                    <Dropdown.Toggle className="nav-link py-0 user-drop-btn">
                                                        <div className="nav-profile-img">
                                                            {/* <img src={require("../../../Assets/img/face1.jpg")} alt="user"/> */}
                                                            {parsedUserData && parsedUserData.name ? (
                                                                <span className='user-name'>
                                                                    {parsedUserData.name
                                                                        .split(" ")
                                                                        .map(word => {
                                                                            // console.log(word.charAt(0)); // Log each initial
                                                                            return word.charAt(0);
                                                                        })
                                                                        .join("")
                                                                        .toUpperCase()
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <span className='user-name'>
                                                                    A
                                                                </span>
                                                            )}


                                                            {/* <span>{userdetails ? userdetails.name : ''}</span> */}
                                                            <span className="availability-status online"></span>
                                                        </div>
                                                        <div className="nav-profile-text">
                                                            {/* <p className="mb-1 text-black">{loggeduserdetails.name}</p> */}
                                                        </div>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="navbar-dropdown">

                                                        <Dropdown.Item href={`${appAbsPath}/portaladmin/dashboard`}>
                                                            <i className="mdi mdi-cached mr-2 text-success"></i>
                                                            Admin Dashboard
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href={`${appAbsPath}`} onClick={handleLogout}>
                                                            <i className="mdi mdi-logout mr-2 text-primary"></i>
                                                            Logout
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                    {
                                        !isIrfsPath && (
                                            <div className='d-none'>
                                            <a href="https://corporate.beyondwalls.com/" target='_blank' className='btn btn-primary sc login-btn d-767-none-i overlay-button text-nowrap btn-login' onClick={() => [createGa4Event('Click', 'Header', 'Corporate Site')]}>
                                                <span>Corporate</span>
                                            </a>
                                            </div>
                                        )
                                    }
                                        {
                                            (!isIrfsPath || (isIrfsPath && isWideScreen)) && !(isProfilePage && !isWideScreen) && (
                                                <ul className="navbar-nav navbar-nav-right">
                                                    <li className="nav-item nav-profile">
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="nav-link py-0 user-drop-btn">
                                                                <div className="nav-profile-img">
                                                                    {/* <img src={require("../../../Assets/img/face1.jpg")} alt="user"/> */}
                                                                    {parsedUserData && parsedUserData.name ? (
                                                                        <span className='user-name'>
                                                                            {parsedUserData.name
                                                                                .split(" ")
                                                                                .map(word => word.charAt(0))
                                                                                .join("")
                                                                                .toUpperCase()
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        <span className='user-name'>
                                                                            A
                                                                        </span>
                                                                    )}
                                                                    <span className="availability-status online"></span>
                                                                </div>
                                                                <div className="nav-profile-text">
                                                                    {/* <p className="mb-1 text-black">{loggeduserdetails.name}</p> */}
                                                                </div>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className="navbar-dropdown">
                                                                <Dropdown.Item href={`${appAbsPath}/profile`}>
                                                                    <i className="mdi mdi-cached mr-2 text-success"></i>
                                                                    Profile
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href={`${appAbsPath}`} onClick={handleLogout}>
                                                                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                                                                    Logout
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </li>
                                                </ul>
                                            )
                                        }

                                    </>
                                )
                            )}
                        </div>

                    </div>
                </div>
            </header>
            <LoginPopup onLogin={onLogin} handleRegisterUserFormHideClick={handleRegisterUserFormHideClick} />
                <BottomNavBar isLoggedIn={parsedUserData !== null} onLogin={onLogin} />

        </>
    );
};
export default Header;