import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { appAbsPath, parsedUserData, createGa4Event } from '../../../Admin/Utils';
import { ReactComponent as HomeIcon } from '../../../../Assets/img/home.svg';
import { ReactComponent as ExploreIcon } from '../../../../Assets/img/explore.svg';
import { ReactComponent as LikedIcon } from '../../../../Assets/img/like.svg';
import { ReactComponent as ProfileIcon } from '../../../../Assets/img/profile.svg';
import { SearchVisibilityContext } from '../../Layouts/SearchVisibilityContext';
import { setCookie } from '../../..//Admin/UserActivatyTracker';

export const getCookie = (name) => {
  return Cookies.get(name);
};

const BottomNavBar = ({ isLoggedIn, onLogin }) => {
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);
  const [redirectPath, setRedirectPath] = useState(null);
  const navigate = useNavigate();
  const { isVisible, setIsVisible, searchOptions, setSearchOptions, irfsProject, setIrfsProject } = useContext(SearchVisibilityContext);
  //irfs path check
  const [isIrfsPath, setIsIrfsPath] = useState(false);
  const [isProfilePage, setIsProfilePage] = useState(false);

  const location = useLocation();
  const isIrfsInUrl = location.pathname.includes('/irfs');
      const [visitedIrfsCookie, setVisitedIrfsCookie] = useState(false);


  useEffect(() => {
    const checkIrfsPath = () => {
        const pathname = location.pathname.split('?')[0]; // Ignoring any query parameters
        const firstSegment = pathname.split('/')[1]; // Get the first segment after the domain
        if (firstSegment === 'irfs' || irfsProject === true) {
            setCookie('VisitedIrfs', 'true', 1);
            setVisitedIrfsCookie(true);
            setIsIrfsPath(true);
            setIrfsProject(true); // Ensure irfsProject is set to true
        }
    };

    const checkProfilePage = () => {
        const pathname = location.pathname.split('?')[0]; // Ignoring any query parameters
        const firstSegment = pathname.split('/')[1]; // Get the first segment after the domain
        const VisitedIrfs = getCookie('VisitedIrfs');
        if (firstSegment === 'profile' && VisitedIrfs === 'true') {
            setIsProfilePage(true);
        } else {
            setIsProfilePage(false);
        }
    };

    checkIrfsPath(); // Initial check for /irfs path
    checkProfilePage(); // Initial check for profile page

    // Optional: if you want to react to route changes
    // window.addEventListener('popstate', checkIrfsPath);
    // return () => window.removeEventListener('popstate', checkIrfsPath);

}, [location]);

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      // Scrolling down
      setVisible(false);
    } else {
      // Scrolling up
      setVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  const handleNavItemClick = () => {
    document.getElementById('overlay').style.display = 'block';
    sessionStorage.setItem('popupShown', 'true');
  };

  const handleLoginSuccess = () => {
    // setLoginPopupVisible(false);
    // if (redirectPath) {
    //   navigate(redirectPath);
    // }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);
  const isActive = (path) => {
    return location.pathname === path ? 'active' : ''; // Check if the path matches the current route
  };

  return (
    <>
    <div className={`d-block d-sm-none ${isIrfsPath || irfsProject || visitedIrfsCookie ? 'irfsnav-class' : ''}`}>
      {/* <div className={`bottom-nav-bar ${visible ? 'slide-up' : 'slide-down'}`}> */}
      <div className={`bottom-nav-bar ${visible ? 'slide-up' : 'slide-down'}`}>
      <Link 
        to={`${isIrfsPath || irfsProject || visitedIrfsCookie ? '/irfs' : '/'}`} 
        className={`nav-item ${isActive(isIrfsPath || irfsProject || visitedIrfsCookie ? '/irfs' : '/')}`}
      >
        <HomeIcon />
        <span>Home</span>
      </Link>

      <Link 
        to={isIrfsInUrl ? `${appAbsPath}/irfs/projects` : `${appAbsPath}/projects`} 
        className={`nav-item ${isActive(isIrfsInUrl ? `${appAbsPath}/irfs/projects` : `${appAbsPath}/projects`)}`}
      >
        <ExploreIcon />
        <span>Explore</span>
      </Link>

      <Link
        to={parsedUserData === null ? '#' : `${appAbsPath}/profile`}
        className={`nav-item ${isActive(`${appAbsPath}/profile`)}`}
        onClick={parsedUserData === null ? () => handleNavItemClick() : null}
      >
        <LikedIcon />
        <span>Liked</span>
      </Link>

      <Link
        to={parsedUserData === null ? '#' : `${appAbsPath}/profile`}
        className={`nav-item ${isActive(`${appAbsPath}/profile`)}`}
        onClick={parsedUserData === null ? () => handleNavItemClick() : () => createGa4Event('header_login', 'HeaderLogIN', 'Log in')}
      >
        <ProfileIcon />
        <span>{parsedUserData === null ? 'Log In' : 'Profile'}</span>
      </Link>
    </div>
      </div>
    </>
  );
};

export default BottomNavBar;
